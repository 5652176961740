<template>
	<div id="footer" class="footer">
		<div class="footerOne">
			<div class="footerOneLeft">
				<div class="footerOneLeftTitle display">
					<img src="@/assets/images/img/logo.png"/>
					<span>{{ $t('footer.leftTitle') }}</span>
				</div>
				<div class="footerOneLeftCen">
					{{ $t('footer.leftDesc') }}
				</div>
				<div class="footerOneLeftBottom">
					<div class="footerOneLeftBottomSame">
						<div class="title">{{ $t('footer.emailTitle') }}</div>
						<div class="desc">{{ $t('footer.emailDesc') }}</div>
					</div>
					<!-- <div class="footerOneLeftBottomSame">
						<div class="title">{{ $t('footer.whatsTitle') }}</div>
						<div class="desc">{{ $t('footer.whatsDesc') }}</div>
					</div> -->
				</div>
			</div>
			<div class="footerOneRight">
				<div class="title display">{{ $t('footer.aboutUs') }}</div>
				<div class="desc">{{ $t('footer.aboutDesc') }}</div>
				<div class="icon display">
					<!-- <img src="@/assets/images/img/negative01.png"/> -->
					<a class="iconImg" :href="'tel:' + $t('footer.whatsDesc')">
						<img src="@/assets/images/img/negative02.png"/>
					</a>
					<a class="iconImg" :href="$t('footer.telegram')" target="_blank">
						<img src="@/assets/images/img/negative03.png"/>
					</a>
				</div>
			</div>
		</div>
		<div class="footerTwo">
			{{ $t('footer.footer') }}
		</div>
		<a class="negativeLink" :href="$t('footer.telegram')" target="_blank">
			<img src="@/assets/images/img/negative.png"/>
		</a>
	</div>
</template>

<script>
	export default {
	  name:'footere',
	  components: {
		
	  },
	  data() {
	  	return{
			footerRisk:false,
			language:localStorage.getItem('lang') == 'zh'?1:2,
			ifcookie:false,

		}
	  },
	  mounted() {
	      // console.log('mounted')
		
	  },
	  created() {
			console.log(typeof(localStorage.getItem('ifcookie')))
			this.ifcookie = (localStorage.getItem('ifcookie') == 'false'||localStorage.getItem('ifcookie') == false)?false:true;
			console.log(this.ifcookie)
			this.language = localStorage.getItem('lang') == 'zh'?1:2;
	  },
	  methods:{
		ifcookieFun(){
			this.ifcookie = false;
			localStorage.setItem('ifcookie',false);
		}
	  }	
	}
</script>

<style lang="scss" scoped>
	.negativeLink{
		width: 1rem;
		height: 1rem;
		position: fixed;
		right: 0.8rem;
		bottom: 0.8rem;
		z-index: 1111111;
		>img{
			width: 1rem;
			height: 1rem;
		}
	}
	#footer{
		width: 100%;
		height: auto;
		.footerOne{
			width: 100%;
			min-height: 4rem;
			background-color: #1C1E53;
			padding: 0.5rem 18.75% 0;
			display: flex;
			flex-wrap: wrap;
			.footerOneLeft{
				width: 50%;
				height: 3.5rem;
				padding-right: 1.6rem;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				.footerOneLeftTitle{
					width: 100%;
					height: auto;
					padding-top: 0.3rem;
					font-size: 0.32rem;
					color: #FFFFFF;
					font-family: 'Aclonica';
					>img{
						height: 0.43rem;
						width: 0.43rem;
						margin-right: 0.13rem;
					}
				}
				.footerOneLeftCen{
					width: 100%;
					height: auto;
					min-height: 0.6rem;
					font-size: 0.16rem;
					color: #AEAEBC;
					line-height: 0.3rem;
					margin: 0.36rem auto 0.86rem;
					font-family: 'Poppins-Medium';
				}
				.footerOneLeftBottom{
					width: 100%;
					height: auto;
					background-color: #FCD980;
					display: flex;
					align-items: center;
					// justify-content: space-around;
					justify-content: space-between;
					.footerOneLeftBottomSame{
						width: 50%;
						height: 0.95rem;
						padding: 0 2.5%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						font-family: 'Poppins-Regular';
						font-size: 0.16rem;
						color: #282938;
						.title{
							font-size: 0.18rem;
							color: #282938;
							font-family: 'Poppins-Medium';
							line-height: 0.35rem;
						}
					}
				}
			}
			.footerOneRight{
				width: 50%;
				height: 3.5rem;
				display: flex;
				flex-direction: column;
				padding-top: 0.15rem;
				.title{
					font-size: 0.48rem;
					color: #FFFFFF;
					font-family: 'Poppins-SemiBold';
					font-weight: 600;
				}
				.desc{
					width: 100%;
					height: auto;
					font-size: 0.16rem;
					color: #AEAEBC;
					line-height: 0.3rem;
					margin: 0.11rem auto 0.25rem;
					font-family: 'Poppins-Regular';
				}
				.icon{
					width: 100%;
					height: auto;
					.iconImg{
						width: 0.22rem;
						height: 0.22rem;
						margin-right: 0.25rem;
						cursor: pointer;
						>img{
							width: 0.22rem;
							height: 0.22rem;
						}
					}
					
				}
			}
		}
		.footerTwo{
			width: 100%;
			height: auto;
			padding: 0.6rem 18.75%;
			// padding: 0.6rem 3.6rem;
			font-size: 0.14rem;
			color: #282938;
			line-height: 0.25rem;
			text-align: center;
		}
	}
	@media only screen and (max-width:1080px){
		#footer{
			width: 100%;
			height: auto;
			.footerOne{
				padding: 0.5rem 0.3rem;
				height: auto;
				.footerOneLeft{
					width:100%;
					padding-right: 0;
					height: 3.5rem;
					.footerOneLeftCen{
						font-size: 0.26rem;
						line-height: 0.3rem;
					}
				}
				.footerOneRight{
					width: 100%;
					padding: 0.5rem 0;
					height: auto;
					.title{
						width: 100%;
						text-align: center;
						margin: 0 auto;
					}
					.desc{
						font-size: 0.26rem;						
						line-height: 0.4rem;
					}
					.icon{
						width: 100%;
						height: auto;
						.iconImg{
							width: 0.3rem;
							height: 0.3rem;
							margin-right: 0.25rem;
							cursor: pointer;
							>img{
								width: 0.3rem;
								height: 0.3rem;
							}
						}
					}
				}
			}
			.footerTwo{
				padding: 0.5rem;
				font-size: 0.24rem;
				line-height: 0.4rem;
			}
		}
		
	}
</style>