<template>
	<div id="activateMembership">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<div class="infoBox" v-if="goodsInfo.name">
			<!-- <div class="infoLeft">
				<goods-magnifier-zoom v-if="this.picture.length>0" :imgList="picture"></goods-magnifier-zoom>
			</div> -->
			<div class="infoLeft">
				<img class="infoLeftImg" :src="goods[current].images"/>
				<div class="infoLeftSmall">
					<img :class="current == index?'infoLeftBorder':''" @click="chooseFun(index,item)" v-for="(item,index) in goods" :key="index" :src="item.images"/>
				</div>
			</div>
			<div class="infoRight">
				<div class="categoryTitle">{{goodsInfo.categoryName}}</div>
				<div class="title">{{goodsInfo.name}}</div>
				<div class="score">
					<div class="imgBox">
						<img v-for="(item,index) in goodsInfo.startNum" :key="index" src="@/assets/images/img/star.png"/>
						<img v-for="(item,index) in (5 - goodsInfo.startNum)" :key="index" src="@/assets/images/img/stargrey.png"/>
					</div>
					<div class="scoreDesc" v-if="goodsInfo.commentList.length>0">
						<span>{{goodsInfo.commentList.length || 0}}customer reviews</span>
					</div>
				</div>
				<span class="xian"></span>
				<div class="price">
					<del v-if="goodsInfo.originalPrice">${{goodsInfo.originalPrice}}</del>
					<span>${{goodsInfo.amt}}</span>
				</div>
				<div class="indexSevenList">
					<div v-for="(sitem,sindex) in goodsInfo.list" v-if="sitem" :key="sindex">
						<img src="@/assets/images/img/triangleGrey.png"/>
						<div>{{sitem}}</div>
					</div>
				</div>
				<div class="button">
					<!-- <div class="buttonimg" @click="handlePropertyReduce">
						<img src="@/assets/images/img/minus.png"/>
					</div>
					<input class="buttoninput" v-model="number" type="text"/>
					<div class="buttonimg" @click="handlePropertyAdd">
						<img src="@/assets/images/img/add.png"/>
					</div> -->
					<router-link :to="'/payment?id='+goodsId+'&number='+number"  class="buttonBuy">
						<img src="@/assets/images/img/cart.png"/>
						<span>{{ $t('buttom.removeObstacles') }}</span>
					</router-link>

<!--          <div @click="goKefu" class="buttonBuy" style="cursor: pointer">-->
<!--            <img src="@/assets/images/img/cart.png"/>-->
<!--            <span>{{ $t('buttom.removeObstacles') }}</span>-->
<!--          </div>-->
				</div>
				<div class="friendshipLink">
					<!-- <img src="@/assets/images/img/member01.png"/> -->
					<a class="iconImg" :href="'tel:' + $t('footer.whatsDesc')">
						<img src="@/assets/images/img/member02.png"/>
					</a>
					<a class="iconImg" :href="$t('footer.telegram')" target="_blank">
						<img src="@/assets/images/img/member03.png"/>
					</a>
				</div>
			</div>
		</div>
		<div class="commentBox" v-if="goodsInfo.commentList && goodsInfo.commentList.length>0">
			<div class="commentTitle">
				<div>
					<span>{{ $t('buttom.reviews') }}({{goodsInfo.commentList.length}})</span>
					<i></i>
				</div>
			</div>
			<div class="listBox">
				<div class="sameList" v-for="(item,index) in goodsInfo.commentList" :key="index">
					<img class="sameListLeft" :src="item.avatar"/>
					<div class="sameListRight">
						<div class="sameLisTitle">
							<div class="titleLeft">
								<div>{{item.nickname}}</div>
								<span>{{item.commentDate}}</span>
							</div>
							<div class="starList">
								<img v-for="(sitem,sindex) in (Number(item.startNum))" :key="sindex" src="@/assets/images/img/star.png"/>
								<img v-for="(sitem,sindex) in (5 - item.startNum)" :key="sindex" src="@/assets/images/img/stargrey.png"/>
							</div>
						</div>
						<div class="desc">
							{{item.content}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import goodsMagnifierZoom from '@/components/goods-magnifier-zoom.vue';
	export default {
		name: 'activateMembership',
		components: {
		  goodsMagnifierZoom: goodsMagnifierZoom,
		},
		data() {
			return {
				info:{},
				number:1,
				imgList: [
					{b: require('@/assets/images/index/indexBg01.png'), s: require('@/assets/images/index/indexBg01.png')},
					{b: require('@/assets/images/index/indexBg02.png'), s: require('@/assets/images/index/indexBg02.png')},
					{b: require('@/assets/images/index/indexBg03.png'), s: require('@/assets/images/index/indexBg03.png')},
					{b: require('@/assets/images/index/indexBg04.png'), s: require('@/assets/images/index/indexBg04.png')},
					{b: require('@/assets/images/index/indexBg05.png'), s: require('@/assets/images/index/indexBg05.png')},
				],
				picture:[],
				goodsId:0,
				categoryId:0,
				goodsInfo:{},
				current:0,
				goods:[]
			}
		},
		mounted() {

		},
		created() {
			// this.info = this.$t('index.indexSeven.list')[this.$route.query.index];
			this.categoryId = this.$route.query.id || 0;
			// this.current = this.$route.query.index || 0;
			console.log(this.info);
			this.goodsList();
			// this.goodsDetails();
		},
		watch:{
			$route(to,from) {
				window.location.reload();
			},
		},
		methods: {
			chooseFun(index,item){
				this.current = index;
				this.number = 1;
				this.goodsId = item.id;
				this.goodsDetails(item.id);
			},
			goodsList(){
				const loading = this.$loading({
				  lock: true,
				  text: this.$t('eMsg.loading'),
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.3)'
				});
				this.$http.get('xcmarket/goodsList?categoryId='+this.categoryId).then((res)=>{
					this.goods = res.data;
					this.goodsId = res.data[this.current].id;
					this.goodsDetails();
					setTimeout(function(){
						loading.close();
					},2500)

				}).catch((err)=>{
				   console.log(err)
				   loading.close();
				})
			},
			handlePropertyAdd() {
				this.number += 1
			},
			handlePropertyReduce() {
				if (this.number === 1) return
				this.number -= 1
			},
			goodsDetails(){
				this.$http.get('xcmarket/goodsDetails?goodsId='+this.goodsId).then((res)=>{
					// res.data.categoryName = this.removeChar(res.data.categoryName,'<br/>');
					res.data.categoryName = (res.data.categoryName).replace(/<b>|<\/b>|<br\/>/g, "");
					res.data['list'] = (res.data.details).split(',');
					var imagesList = (res.data.images).split(',');
					var picList = [];
					imagesList.forEach((item,index)=>{
						picList.push({b:item,s:item})
					})
					this.picture = picList;
					this.goodsInfo = res.data;
				}).catch((err)=>{
				   console.log(err)
				})
			},
      goKefu(){
        window.open('https://t.me/XC600broker01')
      },
			removeChar(str,char){
				var regex = new RegExp(char, 'g');
				var result = str.replace(regex, '');
				return result;
			}
		}
	}
</script>
<style lang="scss" scoped>
	#activateMembership{
		width: 100%;
		height: auto;
		padding: 0.8rem 18.75% 1.67rem;
		.infoBox{
			width: 100%;
			height: auto;
			display: flex;
			flex-wrap: wrap;
			.infoLeft{
				width: 6.24rem;
				height: auto;
				margin-right: 0.75rem;
				.infoLeftImg{
					width: 100%;
					height: 5rem;
					display: block;
				}
				.infoLeftSmall{
					width: 100%;
					height: auto;
					margin-top: 0.2rem;
					display: flex;
					align-items: center;
					overflow: hidden;
					overflow-x: scroll;

					>img{
						width: 1.6rem;
						height: 1.6rem;
						margin-right: 0.2rem;
						cursor: pointer;
						border: 5px solid rgba(0, 0, 0, 0);
					}
					.infoLeftBorder{
						border: 5px solid #FCD980;
					}
				}
			}
			.infoRight{
				flex: 1;
				height: auto;
				font-family: 'Poppins-Regular';
				.categoryTitle{
					width: 100%;
					height: auto;
					font-size: 0.3rem;
					color: #282938;
					font-family: 'Poppins-SemiBold';
				}
				.title{
					width: 100%;
					height: 0.6rem;
					display: flex;
					align-items: center;
					font-size: 0.4rem;
					color: #282938;
					font-family: 'Poppins-SemiBold';
				}
				.score{
					width: 100%;
					height: 0.5rem;
					display: flex;
					align-items: center;
					.imgBox{
						width: auto;
						display: flex;
						align-items: center;
						margin-right: 0.2rem;
						>img{
							width: 0.22rem;
							height: 0.22rem;
						}
					}
					.scoreDesc{
						width: auto;
						display: flex;
						align-items: center;
						font-size: 0.16rem;
						color: #A0A1A7;
						>span{
							margin-right: 0.1rem;
						}
					}
				}
				.xian{
					width: 0.84rem;
					height: 0;
					opacity: 1;
					border: 1px solid #D8D8D8;
					display: block;
				}
				.price{
					width: 100%;
					height: 0.8rem;
					display: flex;
					align-items: center;
					font-size: 0.24rem;
					color: #B3B3B5;
					>span{
						font-size: 0.36rem;
						color: #282938;
						font-family: 'Poppins-Medium';
					}
				}
				.indexSevenList{
					width: 100%;
					height: auto;
					>div{
						width: 100%;
						height: 0.3rem;
						display: flex;
						align-items: center;
						font-size: 0.16rem;
						color: #666772;
						>img{
							width: 0.17rem;
							height: 0.26rem;
							margin-right: 0.1rem;
							transform: scale(0.7);
						}
						>div{
							flex: 1;
							line-height: 0.2rem;
						}
					}
				}
				.button{
					width: 100%;
					height: 1rem;
					display: flex;
					align-items: center;
					.buttonimg{
						width: 0.48rem;
						height: 0.48rem;
						border: 1px solid #D8D8D8;
						display: flex;
						justify-content: center;
						align-items: center;
						>img{
							width: 0.26rem;
							height: 0.26rem;
						}
					}
					.buttoninput{
						width: 0.58rem;
						height: 0.48rem;
						border: 1px solid #D8D8D8;
						display: flex;
						justify-content: center;
						align-items: center;
						margin: 0 0.1rem;
						font-size: 0.28rem;
						color: #000000;
						font-family: 'Poppins-Medium';
						text-align: center;
					}
					.buttonBuy{
						width: 2.7rem;
						height: 0.64rem;
						background-color: #282938;
						display: flex;
						justify-content: center;
						align-items: center;
						margin: 0 0.1rem;
						font-size: 0.2rem;
						color: #F4F6FC;
						font-family: 'Manrope-SemiBold';
						border-radius: 0.35rem;
						margin-left: 0.2rem;
						>img{
							width: 0.27rem;
							height: 0.26rem;
							margin-right: 0.1rem;
						}
					}
				}
				.friendshipLink{
					width: 100%;
					height: auto;
					display: flex;
					align-items: center;
					.iconImg{
						width: 0.2rem;
						height: 0.2rem;
						margin-right: 0.2rem;
						cursor: pointer;
						>img{
							width: 0.2rem;
							height: 0.2rem;
						}
					}
				}
			}
		}
	}
	.commentBox{
		width: 100%;
		height: auto;
		padding-top: 0.2rem;
		.commentTitle{
			width: 100%;
			height: 0.7rem;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #D8D8D8;
			position: relative;
			>div{
				width: 1.4rem;
				height: 0.7rem;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				>i{
					width: 0.6rem;
					height: 2px;
					background: #2405F2;
					position: absolute;
					left: 0.4rem;
					bottom: 0;
					z-index: 11;
				}
			}

		}
		.listBox{
			width: 100%;
			height: auto;
			.sameList{
				width: 100%;
				height: 1.9rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.sameListLeft{
					width: 1.12rem;
					height: 1.12rem;
					border-radius: 0.04rem;
					margin-right: 0.2rem;
				}
				.sameListRight{
					flex: 1;
					height: 1.9rem;
					border-bottom: 1px solid #D8D8D8;
					display: flex;
					flex-direction: column;
					justify-content: center;
					.sameLisTitle{
						width: 100%;
						height: 0.5rem;
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 0.18rem;
						color: #282938;
						font-family: 'Poppins-Medium';
						.titleLeft{
							display: flex;
							align-items: center;
							>span{
								font-size: 0.14rem;
								color: #A0A1A7;
								font-family: 'Poppins-Regular';
								margin-left: 0.2rem;
							}
						}
						.starList{
							display: flex;
							align-items: center;
							>img{
								width: 0.22rem;
								height: 0.22rem;
							}
						}
					}
					.desc{
						width: 100%;
						height:0.6rem;
						font-size: 0.16rem;
						color: #666772;
						line-height: 0.3rem;
						white-space: normal;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
				}
			}
		}
	}
	@media only screen and (max-width:1080px){
		#activateMembership{
			padding: 0.5rem 0.5rem 1rem;
			.infoBox{
				width: 100%;
				height: auto;
				display: flex;
				flex-wrap: wrap;
				.infoLeft{
					width: 100%;
					margin-right: 0;
				}
				.infoRight{
					.title{
						height: 0.8rem;
					}
					.score{
						width: 100%;
						height: 0.8rem;
						.imgBox{
							>img{
								width: 0.3rem;
								height: 0.3rem;
							}
						}
						.scoreDesc{
							font-size: 0.26rem;
						}
					}
					.price{
						height: 1rem;
					}
					.indexSevenList{
						width: 100%;
						height: auto;
						>div{
							font-size: 0.26rem;
							height: auto;
							>img{
								transform: scale(1);
							}
							>div{
								line-height: 0.4rem;
							}
						}
					}
					.button{
						justify-content: center;
						.buttonimg{
							>img{
								width: 0.3rem;
								height: 0.3rem;
							}
						}
						.buttonBuy{
							width: 3.3rem;
							font-size: 0.3rem;
							>img{
								transform: scale(1.2);
							}
						}
					}
					.friendshipLink{
						.iconImg{
							width: 0.3rem;
							height: 0.3rem;
							cursor: pointer;
							>img{
								width: 0.3rem;
								height: 0.3rem;
							}
						}
					}
				}
			}
		}
		.commentBox{
			padding-top: 0.5rem;
			.commentTitle{
				>div{
					font-size: 0.26rem;
				}

			}
			.listBox{
				width: 100%;
				height: auto;
				.sameList{
					.sameListLeft{
						width: 1.12rem;
						height: 1.12rem;
						border-radius: 0.04rem;
						margin-right: 0.2rem;
					}
					.sameListRight{
						.sameLisTitle{
							height: 0.6rem;
							font-size: 0.28rem;
							.titleLeft{
								>span{
									font-size: 0.2rem;
								}
							}
						}
						.desc{
							font-size: 0.24rem;
						}
					}
				}
			}
		}

	}
</style>
