import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from "@/views/Index.vue";
import chiefAnalyst from "@/views/chiefAnalyst.vue";
import indicatorAnalyst from "@/views/indicatorAnalyst.vue";
import activateMembership from "@/views/activateMembership.vue";
import payment from "@/views/payment.vue";
import cancelPayment from "@/views/cancelPayment.vue";
import paymentSuccessful from "@/views/paymentSuccessful.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index
    },
	{
	    path: '/chiefAnalyst',
	    name: 'chiefAnalyst',
	    component: chiefAnalyst
	},
	{
	    path: '/indicatorAnalyst',
	    name: 'indicatorAnalyst',
	    component: indicatorAnalyst
	},
	{
	    path: '/activateMembership',
	    name: 'activateMembership',
	    component: activateMembership
	},
	{
	    path: '/payment',
	    name: 'payment',
	    component: payment
	},
	{
	    path: '/cancelPayment',
	    name: 'cancelPayment',
	    component: cancelPayment
	},
	{
	    path: '/paymentSuccessful',
	    name: 'paymentSuccessful',
	    component: paymentSuccessful
	}
]

const router = new VueRouter({
	mode: 'history',
    routes,
	scrollBehavior (to, from, savedPosition) {
	    if (to.hash) {
	      return {
	        selector: to.hash,
	        // behavior: 'smooth'
	      };
	    }
	    return { x: 0, y: 0 };  // Go to the top of the page if no hash
	  },
})

export default router
