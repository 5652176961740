<template>
	<div id="cancelPayment">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- 内容 -->
		<div class="canceCon">
			<div class="content">
				<div class="title">
					<img src="@/assets/images/img/cancellation.png"/>
					<span>{{$t('buttom.canpay')}}</span>
				</div>
				<div class="list">
					<div class="sameList" v-for="(item,index) in orderLit" :key="index" v-if="item.show">
						<span>{{item.title}}</span>
						<div @click="copyContent(item)">{{item.value}}</div>
					</div>
					<router-link class="botton" to="/">{{$t('buttom.back')}}</router-link>
				</div>
			</div>
		</div>
		<!--  -->
	</div>
</template>

<script>
	export default {
		name: 'cancelPayment',
		components:{
			
		},
		data() {
			return {
				orderInfo:{},
				cardInfo:{
					orderNo:""
				},
				orderLit:[]
			}
		},
		mounted() {
			
		},
		created() {
			this.orderLit = this.$t('payInfo');
			this.cardInfo.orderNo = this.$route.query.token;
			this.confirmPay();
		},
		methods: {
			copyContent(item) {
				// const target = document.getElementById('target-content'); // 获取目标内容的DOM元素
				navigator.clipboard.writeText(item.value); // 使用Clipboard API将目标内容复制到剪贴板上
				// alert("已成功复制！"); // 弹出消息确认复制操作完成
			},
			confirmPay(){
				// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
				this.$http.post('xcmarket/cancelOrder?orderNo='+this.cardInfo.orderNo).then((res)=>{
					// this.orderInfo = res.data;
					this.orderLit.forEach((item,index)=>{
						if(item.show){
							item.value = res.data[item.key]
						}
					})
					console.log(res)
					
				}).catch((err)=>{
				   console.log(err) 
				   // loading.close();
				})
			}
			
		}
	}
</script>
<style lang="scss" scoped>
	body,html{
		background-color: #F4F6FC;
	}
	.canceCon{
		width: 100%;
		height: auto;
		padding: 0.5rem 18.75% 1.2rem;
		.content{
			width: 100%;
			height: auto;
			background-color: #FFFFFF;
			.title{
				width: 100%;
				height: 1.7rem;
				padding: 0 1.5rem;
				background-color: #FDECF1;
				display: flex;
				align-items: center;
				font-size: 0.32rem;
				color: #282938;
				font-family: 'Poppins-Medium';
				>img{
					width: 0.56rem;
					height:0.56rem;
					margin-right: 0.1rem;
				}
			}
			.list{
				width: 100%;
				height: auto;
				padding: 0.5rem 1.5rem 1rem;
				background-color: #FFFFFF;
				.sameList{
					width: 100%;
					height: 0.45rem;
					display: flex;
					align-items: center;
					font-size: 0.16rem;
					color: #666772;
					>span{
						width: 2rem;
						font-size: 0.16rem;
						color: #282938;
					}
					>div{
						cursor: pointer;
					}
				}
				.botton{
					width: 2rem;
					height: 0.64rem;
					background: #282938;
					border-radius:0.4rem;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 0.2rem;
					color: #F4F6FC;
					margin: 0.5rem 0 0;
				}
			}
		}
	}
	@media only screen and (max-width:1080px){
		.canceCon{
			padding: 0.5rem 5% 0.7rem;
			.content{
				.title{
					padding: 0 0.5rem;
					>img{
						width: 0.6rem;
						height:0.6rem;
					}
				}
				.list{
					padding: 0.5rem 0.3rem 1rem;
					.sameList{
						height: 0.6rem;
						font-size: 0.3rem;
						>span{
							width: 2rem;
							font-size: 0.26rem;
						}
					}
					.botton{
						width: 2.5rem;
						height: 0.8rem;
						font-size: 0.3rem;
						margin: 0.8rem auto 0;
					}
				}
			}
		}
		
	}
</style>