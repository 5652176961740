<template>
	<div id="Index">
		<!-- banner -->
		<!-- :style="{backgroundImage:'url('+$t('index.banner.src')+')'}" -->
		<div class="indexbanner">
			<img class="indexbannerBg" :src="$t('index.banner.src')"/>
<!--			<div class="bannerBox">-->
<!--				<div class="bannerTitle">{{ $t('index.banner.title') }}</div>-->
<!--				<div class="bannerDesc">{{ $t('index.banner.subtitle') }}</div>-->
<!--			</div>-->
		</div>

		<div class="indexList" v-for="(item,index) in indexList" :key="index">
			<div class="indexOne" :id="'i'+index" v-if="item.imagePosition == 'right'">
				<div class="indexOneLeft">
					<!-- <div class="subTitle">{{ $t('index.indexOne.subTitle') }}</div> -->
					<div class="title" v-html="item.title"></div>
					<div class="desc"  v-html="item.descEn"></div>
				</div>
				<img class="indexOneRight" :src="item.image"/>
			</div>
			<div class="indexOne indexTwo" id="liveStream" v-if="item.imagePosition == 'left'">
				<img class="indexOneRightnothing" :src="item.image"/>
				<div class="indexOneLeft indexTwoRight">
					<!-- <div class="subTitle">{{ $t('index.indexTwo.subTitle') }}</div> -->
					<div class="title" v-html="item.title"></div>
					<div class="desc"  v-html="item.descEn"></div>
				</div>
				<img class="indexOneRightBottom" :src="item.image"/>
			</div>
			<div class="indexFour indexThree" id="latestResults" v-if="item.imagePosition == 'bottom'">
				<div class="title" v-html="item.title"></div>
				<div class="desc"  v-html="item.descEn"></div>
				<img class="indexFourImg" @click="imageLink(item)" :src="item.image"/>
			</div>
			<!--  内容五 最新结果-->
			<!-- <div class="indexFive" id="latestResults">
				<div class="title">{{ item.title }}</div>
				<img class="latestResultsImg" :src="item.image"/>
				<div class="content">
					<div class="contentTitle">
						<div v-for="(item,index) in $t('index.indexFive.titleList')" :key="index">{{item.title}}</div>
					</div>
					<div class="contentList" v-for="(item,index) in $t('index.indexFive.list')" :key="index">
						<div class="one">{{item.currencyPair}}<span>{{item.view}}</span></div>
						<div class="two">{{item.buySell}}</div>
						<div class="three">{{item.ClosedAt}}</div>
						<div class="four">{{item.hit}}</div>
						<div class="five">{{item.proftLoss}}</div>
					</div>
				</div>
			</div> -->
		</div>
		<!-- 内容六 我的工具 v-if="item.id == '1731867533463592961'"-->
		<div class="indexSix" id="ourTools">
			<div class="title">{{ $t('index.indexSix.title') }}</div>
			<div class="desc"  v-html="$t('index.indexSix.desc')"></div>
			<img class="ourToolsImg" :src="$t('index.indexSix.cover')"/>
			<!-- <div class="indexCon">
				<img class="indexSixLeft" src="@/assets/images/index/indexBg05.png"/>
				<div class="indexSixRight">
					<div class="indexSixRightSame" v-for="(item,index) in $t('index.indexSix.list')" :key="index">
						<span>0{{index + 1}}</span>
						<div>{{item.title}}</div>
					</div>
				</div>
			</div> -->
			<!-- <div class="indexCon">
				<div class="swiper-container indexSixLeft">
					<div class="swiper-wrapper">
						<img class="swiper-slide" v-for="(item,index) in $t('index.indexSix.newList')" :key="index" :src="item.img"/>
					</div>
					<div class="swiper-button-prev"></div>
					<div class="swiper-button-next"></div>
				</div>
				<div class="indexSixRight">
					<div class="indexSixRightTitle">{{$t('index.indexSix.newList')[currentBanner].title}}</div>
					<div class="indexSixRightDesc">{{$t('index.indexSix.newList')[currentBanner].desc}}</div>
				</div>
			</div> -->
			<div class="swiper-container indexConOurTools">
				<div class="swiper-wrapper">
					<div class="swiper-slide ourToolsSwiperSlide" v-for="(item,index) in $t('index.indexSix.newList')" :key="index">
						<div class="indexSixLeft">
							<img :src="item.img"/>
						</div>
						<div class="indexSixRight">
							<div class="indexSixRightTitle">{{item.title}}</div>
							<div class="indexSixRightDesc">{{item.desc}}</div>
						</div>
					</div>
				</div>
				<div class="swiper-button-prev"></div>
				<div class="swiper-button-next"></div>
			</div>
		</div>

		<!-- 内容7 会员开通-->
		<div class="indexSeven" id="Membership01" style="display: none;">
			<div class="title">{{ $t('index.indexSeven.title') }}</div>
			<div class="indexSevenCon">
				<div class="samelist" :class="vipCurrent == index?'samelistCurrent':''"
				v-for="(item,index) in goods" :key="index"
				@click="vipCurrent = index">
					<div class="sameSevenTitle" :class="vipCurrent == index?'samelistCurrentColor':''">
						<div>${{item.amt}}<span>vip</span></div>
						<span class="sameSevenTitleSpan" v-if="item.originalPrice">WAS ${{item.originalPrice}}</span>
					</div>
					<div class="desc" :class="vipCurrent == index?'samelistCurrentColor':''">{{item.name}}</div>
					<div class="indexSevenList">
						<div :class="vipCurrent == index?'samelistCurrentColor':''"
						v-for="(sitem,sindex) in item.list" :key="sindex" v-if="sitem">
							<img v-if="vipCurrent == index" src="@/assets/images/img/triangle.png"/>
							<img v-if="vipCurrent != index" src="@/assets/images/img/triangleGrey.png"/>
							<div>{{sitem}}</div>
						</div>
					</div>
					<div class="indexSevenBottom">
						<router-link class="indexSevenBottomA" :class="vipCurrent == index?'indexSevenBottomColor':''"
						:to="'/activateMembership?id='+item.id+'&index='+index">{{$t('buttom.choocePlan')}}</router-link>
					</div>
				</div>
			</div>
		</div>
		<!-- 新的产品 -->
		<div class="product" id="Membership">
			<div class="title">{{ $t('index.indexSeven.title') }}</div>
			<div class="proCon">
				<router-link class="samePro" v-for="(item,index) in goods" :key="index"
				:to="item.goodsNum > 0?('/activateMembership?id='+item.id+'&index=3'):''">
					<img :src="item.cover"/>
					<div><span v-html="item.title"></span><i v-if="item.remark">{{item.remark}}</i></div>
				</router-link>
			</div>
		</div>
		<!--  -->
		<div class="Support">
			<div class="title">{{ $t('index.support.title') }}</div>
			<div class="supCon">
				<div class="samePro" v-for="(item,index) in $t('index.support.list')" :key="index">
					<img :src="item.img"/>
					<div class="sameTitle">{{item.title}}</div>
					<div class="sameDesc" v-html="item.desc"></div>
				</div>
			</div>
		</div>
		<!-- 问题解答 -->
		<div class="problemBox" id="answerQuestions">
			<div class="indexTitle">{{$t('faq.title')}}</div>
			<div class="sameproblem"  v-for="(item,index) in list" :key="index">
				<div class="indexTitle">{{item.title}}</div>
				<div class="indexSubTitle">{{item.desc}}</div>
				<div class="samePlateSix" :class="sitem.show?'samePlateSixSe':''" v-if="item.list" v-for="(sitem,sindex) in item.list" :key="sindex">
					<div class="samePlateSixTitle" @click="showPlateSix(index,sindex)">
						<span>{{sitem.title}}</span>
						<img :class="sitem.show?'samePlateSixImg':''" src="@/assets/images/img/dropdown.png"/>
					</div>
					<div class="samePlateSixDesc">
						<div class="samesixDec" v-html="sitem.desc"></div>
						<router-link to="" class="sameAccounta" v-if="sitem.button">
							<span>{{sitem.button}}</span>
							<img src="@/assets/images/img/more.png"/>
						</router-link>
					</div>
				</div>
			</div>

		</div>
		<!--  -->
	</div>
</template>

<script>
	export default {
		name: 'Index',
		data() {
			return {
				currentBanner:0,
				vipCurrent:1,
				list:[],
				indexList:[],
				goods:[]
			}
		},
		mounted() {
			var that = this;
			window.addEventListener('setItemEvent', function(e) {
			      // const newdata = JSON.parse(e.newValue)
			   that.list = that.$t('faq.list');
			})
			var swiperBanner = new Swiper('.indexConOurTools', {
				loop: true,
				autoplayDisableOnInteraction : false,
				autoplay: 5000,
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				onSlideChangeEnd: function(swiper){
				 // console.log(swiper.activeIndex)
				 that.currentBanner = swiper.activeIndex>2?2:swiper.activeIndex;
				},
			})
		},
		created() {
			this.list = this.$t('faq.list');
			this.indexItems();
			// this.goodsList();
			this.goodsCategoryList();
		},
		methods: {
			imageLink(item){
				if(item.imageLink){
					// this.$router.push({ path:item.imageLink})
					 window.open(item.imageLink)
				}
			},
			showPlateSix(index,sindex){
				console.log(index,sindex)
				if(!this.list[index].list[sindex].show){
					this.list.forEach((item,i)=>{
						item.list.forEach((sitem,s)=>{
							if(sindex == s&&i == index){
								sitem.show = true
							}else{
								sitem.show = false
							}
						})

					})
				}else{
					this.list[index].list[sindex].show = !this.list[index].list[sindex].show
				}

			},
			indexItems(){
				const loading = this.$loading({
				  lock: true,
				  text: this.$t('eMsg.loading'),
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.3)'
				});
				this.$http.get('xcmarket/indexItems').then((res)=>{
					// console.log(res)
					this.indexList = res.data;
					loading.close();
				}).catch((err)=>{
				   console.log(err)
				   loading.close();
				   // loading.close();
				})
			},
			goodsCategoryList(){
				this.$http.get('xcmarket/goodsCategoryList').then((res)=>{
					this.goods = res.data;
					// console.log(this.goods)

				}).catch((err)=>{
				   console.log(err)
				   // loading.close();
				})
			},
			goodsList(){
				this.$http.get('xcmarket/goodsList').then((res)=>{
					this.goods = res.data;
					this.goods.forEach((item,index)=>{
						item['list'] = (item.details).split(',')
					})
					// console.log(this.goods)

				}).catch((err)=>{
				   console.log(err)
				   // loading.close();
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.indexList{
		width: 100%;
		height: auto;
	}
	.swiper-container,.swiper-wrapper,.swiper-slide{
		width: 100%;
		min-height:4.23rem;
		height:auto;
	}
	.indexConOurTools{
		width: 100%;
		height: auto;
		margin: 1.2rem auto 0;
		.ourToolsSwiperSlide{
			// width: 100% !important;
			height: auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.indexSixLeft{
				width: 7.46rem;
				height: 4.23rem;
				margin-right: 0.46rem;
				padding: 0 0.1rem;
				>img{
					width: 100%;
					height: 100%;
					display: block;
				}


			}
			.indexSixRight{
				flex: 1;
				height: auto;
				display: flex;
				flex-direction: column;
				// justify-content: center;
				.indexSixRightSame{
					width: 100%;
					height: auto;
					display: flex;
					align-items: center;
					font-family: 'Poppins-Medium';
					border-bottom: 1px solid #C2BBFF;
					padding: 0.25rem 0;
					>span{
						width: 0.9rem;
						height: auto;
						font-size: 0.24rem;
						color: #2405F2;
						text-align: center;
					}
					>div{
						font-size: 0.24rem;
						color: #282938;
					}
				}
				.indexSixRightTitle{
					width: 100%;
					height: auto;
					font-size: 0.38rem;
					color: #282938;
					font-family: 'Poppins-SemiBold';
					margin: 0.16rem auto;
					text-align: left;
				}
				.indexSixRightDesc{
					width: 100%;
					height: auto;
					font-size: 0.24rem;
					color: #666772;
					font-family: 'Poppins-Regular';
					line-height: 0.35rem;
					text-align: left;
				}
			}

		}

	}

	.indexbanner{
		width: 100%;
		height: 7.6rem;
		// background-size: cover;
		position: relative;
		.indexbannerBg{
			width: 100%;
			height: 7.6rem;
			background-size: cover;
			// filter: blur(1px);
			position: absolute;
			left: 0;
			top: 0;
			z-index:2;
		}
		.bannerBox{
			width: 100%;
			height: 7.6rem;
			padding-top: 3rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			position: absolute;
			left: 0;
			top: 0;
			z-index:3;
			.bannerTitle{
				font-size: 0.4rem;
				color: #FFFFFF;
				font-family: 'Poppins-Medium';
				text-align: center;
			}
			.bannerDesc{
				font-size: 0.64rem;
				color: #FFFFFF;
				// margin:0.24rem 0 0;
				font-family: 'Poppins-SemiBold';
				text-align: center;
			}
		}
	}
	.product{
		width: 100%;
		height: auto;
		padding: 0.8rem 18.75% 0;
		background-color: #F4F6FC;
		.title{
			width: 100%;
			height: auto;
			font-size: 0.38rem;
			color: #282938;
			font-family: 'Poppins-SemiBold';
			margin: 0 auto 0.8rem;
			text-align: center;
		}
		.proCon{
			width: 100%;
			height: auto;
			display: flex;
			flex-wrap: wrap;
			.samePro{
				width: 31.6%;
				height: auto;
				margin-bottom: 0.5rem;
				margin-left: 1.7%;
				>img{
					width: 1rem;
					height: 1rem;
					margin: 0 auto 0.1rem;
					display: block;
				}
				>div{
					width: 70%;
					height: 1.4rem;
					font-size: 0.16rem;
					color: #282938;
					font-family: 'Poppins-Regular';
					line-height: 0.3rem;
					text-align: center;
					margin: 0 auto;
					>i{
						font-style: normal;
						opacity: 0.8;
					}
				}
			}
		}
	}
	.Support{
		width: 100%;
		height: auto;
		padding: 0.8rem 18.75% 0;
		background-color: #FFFFFF;
		.title{
			width: 100%;
			height: auto;
			font-size: 0.38rem;
			color: #282938;
			font-family: 'Poppins-SemiBold';
			margin: 0 auto 0.8rem;
			text-align: center;
		}
		.supCon{
			width: 100%;
			height: auto;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.samePro{
				width: 3.7rem;
				height: 4.1rem;
				background: #F4F6FC;
				padding: 0.55rem 0.15rem 0;
				>img{
					width: 1.05rem;
					height: 1.05rem;
					margin: 0 auto 0.1rem;
					display: block;
				}
				.sameTitle{
					width: 100%;
					height: 0.65rem;
					font-size: 0.24rem;
					color: #282938;
					font-family: 'Poppins-Medium';
					// line-height: 0.3rem;
					text-align: center;
					margin-top: 0.2rem;
				}
				.sameDesc{
					width: 100%;
					height: auto;
					font-size: 0.16rem;
					color: #282938;
					font-family: 'Poppins-Regular';
					line-height: 0.25rem;
					text-align: center;
				}
			}
		}
	}

	.indexOne{
		width: 100%;
		height: auto;
		padding: 1.07rem 18.75% 1.47rem;
		background-color: #FFFFFF;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.indexOneLeft{
			flex: 1;
			height: auto;
			padding-right: 0.5rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.subTitle{
				width: 100%;
				height: auto;
				font-size: 0.18rem;
				color: #000000;
				font-family: 'Poppins-Medium';
			}
			.title{
				width: 100%;
				height: auto;
				font-size: 0.38rem;
				color: #282938;
				font-family: 'Poppins-SemiBold';
				margin: 0.16rem auto;
			}
			.desc{
				width: 100%;
				height: auto;
				font-size: 0.2rem;
				color: #666772;
				font-family: 'Poppins-Regular';
				line-height: 0.35rem;
			}
		}
		.indexOneRight{
			width: 5.8rem;
			height: 4rem;
		}
		.indexOneRightnothing{
			width: 5.8rem;
			height: 4rem;
			display: block;
		}
		.indexOneRightBottom{
			width: 5.8rem;
			height: 4rem;
			display: none;
		}
	}
	.indexTwo{
		padding: 1.5rem 18.75%;
		background-color: #F4F6FC;
		.indexTwoRight{
			padding: 0 0 0 0.5rem;
		}
	}
	.indexThree{
		padding: 1.07rem 18.75%;
	}
	.indexFour{
		width: 100%;
		height: auto;
		padding: 0.6rem 18.75% 1.7rem;
		background-color: #FFFFFF;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.title{
			font-size: 0.48rem;
			color: #282938;
			font-family: 'Poppins-SemiBold';
			line-height: 0.6rem;
			text-align: center;
		}
		.desc{
			width: 100%;
			height: auto;
			font-size: 0.3rem;
			color: #282938;
			font-family: 'Poppins-Medium';
			line-height: 0.45rem;
			text-align: center;
			margin-top: 0.3rem;
		}
		.indexFourImg{
			display: block;
			width: 100%;
			height: auto;
			margin: 0.6rem auto 0;
		}
	}
	.indexFive{
		width: 100%;
		height: auto;
		padding: 0.05rem 18.75% 1.35rem;
		background-color: #F4F6FC;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.title{
			width: 100%;
			height: 2.2rem;
			font-size: 0.48rem;
			color: #282938;
			font-family: 'Poppins-SemiBold';
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.latestResultsImg{
			width: 100%;
			height: auto;
			margin: 0 auto;
		}
		.content{
			width: 100%;
			height: auto;
			.contentTitle{
				width: 100%;
				height: 0.56rem;
				background-color: #DDE3F4;
				display: flex;
				>div{
					width: 20%;
					height: 0.56rem;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 0.2rem;
					color: #282938;
				}
			}
			.contentList{
				width: 100%;
				height: 0.56rem;
				border-bottom: 1px solid #DDE2F4;
				display: flex;
				>div{
					width: 20%;
					height: 0.56rem;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 0.18rem;
					color: #666772;
					font-family: 'Poppins-Regular';
				}
				.one{
					font-size: 0.18rem;
					color: #282938;
					>span{
						color: #F62E2E;
						font-size: 0.16rem;
						margin-left: 0.1rem;
					}
				}
				.two{
					color: #F62E2E;
				}
				.four{
					color: #0AB257;
				}
				.five{
					color: #282938;
				}
			}
		}
	}
	.indexSix{
		width: 100%;
		height: auto;
		padding: 0.5rem 12.5% 1.5rem;
		background-color: #FFFFFF;
		.title{
			width: 100%;
			// height: 2.2rem;
			height: auto;
			line-height: 0.6rem;
			text-align: center;
			font-size: 0.48rem;
			color: #282938;
			font-family: 'Poppins-SemiBold';
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.desc{
			width: 100%;
			height: auto;
			font-size: 0.3rem;
			color: #282938;
			font-family: 'Poppins-Medium';
			line-height: 0.45rem;
			text-align: center;
			margin-top: 0.3rem;
		}
		.ourToolsImg{
			width: 100%;
			// width: 70%;
			height: auto;
			margin: 0.5rem auto 0.3rem;
			display: block;
		}
		.indexCon{
			width: 100%;
			height: auto;
			margin: 1.2rem auto 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.indexSixLeft{
				width: 7.46rem;
				height: 4.23rem;
				margin-right: 0.46rem;
				>img{
					width: 100%;
					height: 100%;
					display: block;
				}


			}
			.indexSixRight{
				flex: 1;
				height: auto;
				display: flex;
				flex-direction: column;
				// justify-content: center;
				.indexSixRightSame{
					width: 100%;
					height: auto;
					display: flex;
					align-items: center;
					font-family: 'Poppins-Medium';
					border-bottom: 1px solid #C2BBFF;
					padding: 0.25rem 0;
					>span{
						width: 0.9rem;
						height: auto;
						font-size: 0.24rem;
						color: #2405F2;
						text-align: center;
					}
					>div{
						font-size: 0.24rem;
						color: #282938;
					}
				}
				.indexSixRightTitle{
					width: 100%;
					height: auto;
					font-size: 0.38rem;
					color: #282938;
					font-family: 'Poppins-SemiBold';
					margin: 0.16rem auto;
					text-align: left;
				}
				.indexSixRightDesc{
					width: 100%;
					height: auto;
					font-size: 0.24rem;
					color: #666772;
					font-family: 'Poppins-Regular';
					line-height: 0.35rem;
					text-align: left;
				}
			}
		}
	}
	.indexSeven{
		width: 100%;
		height: auto;
		padding: 1.5rem 18.75%;
		background-color: #F4F6FC;
		.title{
			width: 100%;
			height: auto;
			text-align: center;
			font-size: 0.48rem;
			color: #282938;
			font-family: 'Poppins-SemiBold';
		}
		.indexSevenCon{
			width: 100%;
			height: auto;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			font-family: 'Poppins-Regular';
			margin-top: 0.6rem;
			.samelist{
				width: 31.6%;
				// height: 7.26rem;
				height: auto;
				padding: 0.2rem 0.3rem 0.5rem;
				border-radius: 0.12rem;
				margin-bottom: 0.5rem;
				margin-left: 1.7%;
				.sameSevenTitle{
					width: 100%;
					height: 1rem;
					display: flex;
					flex-direction: column;
					// justify-content: center;
					// align-items: center;
					>div{
						font-size: 0.38rem;
						color: #282938;
						font-family: 'Poppins-SemiBold';
						display: flex;
						align-items: center;
						>span{
							font-size: 0.16rem;
							color: #2405F2;
							margin-left: 0.1rem;
							font-family: 'Poppins-Regular';
						}
					}
					.sameSevenTitleSpan{
						display: block;
						width: 100%;
						font-size: 0.16rem;
						color: #666772;
						font-family: 'Poppins-Regular';
					}
				}
				.desc{
					width: 100%;
					height: 0.6rem;
					display: flex;
					align-items: center;
					font-size: 0.24rem;
					color: #282938;
					font-family: 'Poppins-Medium';
				}
				.samelistCurrentColor{
					color: #FFFFFF !important;
					>div{
						color: #FFFFFF !important;
						>span{
							color: #FFFFFF !important;
						}
					}
					.sameSevenTitleSpan{
						color: #FFFFFF !important;
					}

				}
				.indexSevenList{
					width: 100%;
					height: auto;
					>div{
						width: 100%;
						height: 0.4rem;
						display: flex;
						align-items: center;
						font-size: 0.16rem;
						color: #666772;
						>img{
							width: 0.17rem;
							height: 0.26rem;
							margin-right: 0.1rem;
						}
						>div{
							flex: 1;
							line-height: 0.2rem;
						}
					}
				}
				.indexSevenBottom{
					width: 100%;
					height: auto;
					margin-top: 0.4rem;
					display: flex;
					justify-content: center;
					align-items: center;
					.indexSevenBottomA{
						width: 2.54rem;
						height: 0.64rem;
						background: #282938;
						border-radius: 0.4rem;
						font-size: 0.18rem;
						color: #F4F6FC;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
			.samelistCurrent{
				background-color: #1C1E53;
			}
		}
	}

	.indexSevenBottomColor{
		background: #FCD980 !important;
		color: #282938 !important;
	}
	.problemBox{
		width: 100%;
		height: auto;
		margin: 0 auto;
		padding: 1.07rem 18.75% 1.47rem;
		.sameproblem{
			width:100%;
			height: auto;
			.samePlateSix{
				width: 100%;
				height: 1rem;
				border-bottom: 1px solid #EAECEC;
				overflow: hidden;
				transition: all 0.5s;
				.samePlateSixTitle{
					width: 100%;
					height: 1rem;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 0.2rem;
					color: #202020;
					// font-weight: 700;
					>img{
						width: 0.2rem;
						height: 0.2rem;
						transition: all 0.3s;
					}
					.samePlateSixImg{
						transform: rotate(180deg);
						transition: all 0.3s;
					}
				}
				.samePlateSixDesc{
					width: 100%;
					height: auto;
					padding-bottom: 0.3rem;
					font-size: 0.16rem;
					color: #202020;
					line-height: 0.35rem;
					.ifSixListTitle{
						font-weight: 700;
					}
				}
				.sameAccounta{
					width: 2.36rem;
					height: 0.46rem;
					background: #D51820;
					opacity: 1;
					border-radius: 0.25rem;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 0.16rem;
					color: #FFFFFF;
					margin: 0.3rem auto;
					>img{
						width: 0.12rem;
						height: 0.12rem;
						margin-left: 0.1rem;
					}
				}
			}
			.samePlateSixSe{
				height: auto;
				transition: all 0.5s;
			}
		}

	}
	@media only screen and (max-width:1080px){
		.indexConOurTools{
			.ourToolsSwiperSlide{
				flex-wrap: wrap;
				justify-content: flex-start;
				.indexSixLeft{
					width: 100%;
					height: 4rem;
					margin-right: 0;
				}
			}

		}

		.product{
			padding: 0.8rem 5% 0;
			.proCon{
				justify-content: space-between;
				.samePro{
					width: 48%;
					height: auto;
					margin-left:0;
					>img{
						width: 1.5rem;
						height: 1.5rem;
						margin: 0 auto 0.1rem;
						display: block;
					}
					>div{
						width: 100%;
						font-size: 0.26rem;
						line-height: 0.5rem;
					}
				}
			}
		}
		.Support{
			width: 100%;
			height: auto;
			padding: 0.8rem 5% 0;
			background-color: #FFFFFF;
			.supCon{
				.samePro{
					width: 100%;
					height: auto;
					margin-bottom: 0.5rem;
					padding: 0.55rem 0.15rem 1rem;
					>img{
						width: 1.5rem;
						height: 1.5rem;
					}
					.sameTitle{
						font-size: 0.3rem;
					}
					.sameDesc{
						font-size: 0.26rem;
						line-height: 0.5rem;
					}
				}
			}
		}

		.indexbanner{
			.bannerBox{
				justify-content: center;
				padding: 0 0.3rem;
				.bannerTitle{
					font-size: 0.24rem;
				}
				.bannerDesc{
					font-size: 0.3rem;
					margin-top: 0.2rem;
				}
			}
		}

		.indexOne{
			padding: 0.5rem 0.5rem 1rem;
			flex-wrap: wrap;
			.indexOneLeft{
				flex: 100%;
				padding-right: 0;
				.subTitle{
					font-size: 0.24rem;
				}
				.title{
					font-size: 0.4rem;
					text-align: center;
					margin: 0.25rem auto;
				}
				.desc{
					font-size: 0.28rem;
					line-height: 0.4rem;
				}
			}
			.indexOneRight{
				width: 100%;
				height: auto;
				display: block;
				margin-top: 0.5rem;
			}
			.indexOneRightnothing{
				width: 100%;
				height: auto;
				display: block;
				margin-top: 0.5rem;
				display: none;
			}
			.indexOneRightBottom{
				width: 100%;
				height: auto;
				display: block;
				margin-top: 0.5rem;
				display: block;
			}
		}
		.indexTwo .indexTwoRight{
			padding-left: 0;
		}
		.indexFour{
			padding: 0.5rem 0.5rem 1rem;
		}
		.indexFive{
			padding: 0.5rem 0.5rem 1rem;
			.title{
				height: 1rem;
			}
			.content{
				margin-top: 0.5rem;
			}
		}
		.indexSix{
			padding: 0.5rem 0.5rem 1rem;
			.indexCon{
				margin: 0.5rem auto 0;
				flex-wrap: wrap;
				.indexSixLeft{
					width: 100%;
					height: auto;
					margin-bottom: 0.5rem;
					margin-right: 0;
				}
			}
		}
		.indexSeven{
			height: auto;
			padding: 0.5rem 0.5rem 1rem;
			.indexSevenCon{
				flex-wrap: wrap;
				margin-top: 0.6rem;
				.samelist{
					width: 100%;
					margin-bottom: 0.5rem;
					height: auto;
					padding: 0.5rem 0.3rem;
					margin-left: 0;
					.sameSevenTitle{
						height: 1.3rem;
						>div{
							font-size: 0.46rem;
							>span{
								font-size: 0.22rem;
							}
						}
						.sameSevenTitleSpan{
							font-size: 0.26rem;
						}
					}
					.indexSevenList{
						>div{
							height: auto;
							font-size: 0.26rem;
							>div{
								line-height: 0.5rem;
							}
						}
					}
					.indexSevenBottom{
						.indexSevenBottomA{
							width: 50%;
							height: 0.8rem;
							font-size: 0.3rem;
						}
					}
				}
				.samelistCurrent{
					background-color: #1C1E53;
				}
			}
		}
		.problemBox{
			width: 100%;
			padding: 1.05rem 0.3rem 2.6rem ;
			.indexSubTitle{
				padding: 0;
			}
		}
	}
</style>
