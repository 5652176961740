module.exports = {
	footer:{
		footer:'Caution:Transactions may result in financial losses. To trade only with the funds you are prepared to lose, you must recognize that you may lose all the funds in your trading account due to factors beyond your control. We do not know the level of funds you trade or the level of risk you take on each trade. You must make your own financial decisions and we accept no responsibility for the resulting gains or losses.',
		leftTitle:'XC600',
		leftDesc:'Extraordinary Care！We are always open to discuss your project and improve your online presence.',
		emailTitle:'Email us at',
		emailDesc:'xcprofx@xcprofx.com',
		whatsTitle:'WhatsApp',
		whatsDesc:'0927 6277 28525',
		telegram:"https://t.me/XC600broker01",
		aboutUs:'About Us',
		aboutDesc:'XC 600 is a global financial services platform that provides multilingual investor education live streaming, advanced trading tools, and a wide range of trading strategy services. The XC600 is a symbol of the extraordinary care we provide for you. All information contained in this website is the personal opinion or belief of the author. None of this data is advice or financial advice in any sense.'
	},
	login: {
	    signUp:"Sign up",
	    signIn: "Sign in",
		signOut:"Sign out",
		submit:'Submit',
		tips:'Account review in progress',
		signtips:'Confirm logout?'
	},
	language:{
		en:'English',
		zh:'Chinese',
		home:'home'
	},
	buttom:{
		readMore:"Read More",
		removeObstacles:"Go to activate",
		reviews:"Reviews",
		payLeft:"Billing details",
		payRight:"YOUR ORDER",
		product:"Product",
		subtotal:"Subtotal",
		number:"Number",
		total:"Total",
		policy:"Your personal data will be used to process your order.support your experience throughout this website, and forother purposes described in our privacy policy",
		condition:"I have read and agree to the website terms andconditions",
		placeOrder:"PLACE ORDER",
		choocePlan:"Chooce Plan",
		moreVideo:"For more live videos",
		canpay:"Cancel payment！",
		paySuc:"Payment success！",
		payFail:"Payment failed",
		back:"Back",
		tips:"Please enter ",
		tipss:"Please select ",
	},
	eMsg:{
		loading:"loading"
	},
	// {id:1,title:'Chief Analyst Live',src:'/chiefAnalyst',show:false},{id:2,title:'Indictor Education Iive',src:'/indicatorAnalyst',show:true}
	nav:[
		{id:1,title:'Home',src:'/',list:[],show:true},
		{id:2,title:'Latest Results',src:'#latestResults',list:[],show:true},
		{id:3,title:'Our Tools',src:'#ourTools',list:[],show:true},
		{id:4,title:'Products',src:'#Membership',list:[],show:true},
		{id:5,title:'Live Stream',src:'/indicatorAnalyst',list:[],show:true},
		{id:6,title:'Finance Course',src:'#financeCourse',list:[],show:true},
		// {id:7,title:'Recommended Broker',src:'https://secure.cwgchinese.com/#/signup/23544/F2/B1',list:[],show:true},
		{id:7,title:'Recommended Broker',src:'/',list:[],show:true},
		{id:8,title:'Global cooperation',src:'/',list:[],show:true},
		{id:9,title:'About Us',src:'#footer',list:[],show:true},
		{id:10,title:'FAQ',src:'#answerQuestions',list:[],show:true}
	],
	index:{
		banner:{
			title:"XC Markets Service",
			subtitle:'Education for finance and fintech',
			src:require("@/assets/images/banner/indexBanner.png")
		},
		indexOne:{
			subTitle:'Use Client-first',
			title:'Financial Training',
			desc:'Designed to improve your financial knowledge and help you improve your trading skills so you can trade with more confidence! Whether you are a beginner or an experienced trader, our experienced market analyst training, short video course instruction will explain financial knowledge to you. We are committed to being with you every step of the way as you grow in your financial literacy, laying the foundation for your financial trading by providing valuable financial education.'
		},
		indexTwo:{
			subTitle:'Use Client-first',
			title:'Live Stream',
			desc:"The world's only full-time 10-hour live video broadcast of analysts online, taking you to interpret the major news and events in the international economic markets and analyze the trading strategies of popular products. Join us for an online video training where our chief analysts will share their trading knowledge and answer your questions.<br/>Live indicators 24 hours a day, get quality technical support to help you discover the secrets of successful trend trading, online teaching, easy grasp."
		},
		indexThree:{
			subTitle:'Use Client-first',
			title:'Trading Tools',
			desc:'Outstanding automatic identification software to enhance your trading experience. The Radar Indicator is an online charting software for technical analysis and trading that provides a range of actionable trading aids, accurate research, and automatic monitoring of market dynamics and trends, allowing you to receive signals when new market trends are forming.'
		},
		indexFour:{
			title:'NOVEMBER SPECIAL<br/> OFFER'
		},
		indexFive:{
			title:'Latest Results',
			titleList:[
				{id:1,title:"Currency Pair"},
				{id:2,title:"Buy Sell"},
				{id:3,title:"Closed at"},
				{id:4,title:"Hit TP/SL"},
				{id:5,title:"Proft Loss"}
			],
			list:[
				{id:1,currencyPair:"EUR/CAD",view:"view",buySell:"SELL",ClosedAt:"1.5450",hit:"TP-2",proftLoss:"+ 50 pips"},
				{id:2,currencyPair:"CHF/JPY",view:"view",buySell:"SELL",ClosedAt:"116.62",hit:"TP-2",proftLoss:"+ 100 pips"},
				{id:3,currencyPair:"EUR/USD",view:"view",buySell:"SELL",ClosedAt:"1.2077",hit:"TP-2",proftLoss:"+ 50 pips"},
				{id:4,currencyPair:"NZD/CHF",view:"view",buySell:"SELL",ClosedAt:"0.6250",hit:"TP-2",proftLoss:"+ 50 pips"}
			]
		},
		indexSix:{
			title:'Our Tools',
			desc:"Telegram to MT4/5 Trade Copier <br/> Automatically copy  signals from Telegram to MT4 / 5 <br/> Whether you are traveling, sleeping, you will never miss a deal again!",
			cover:require("@/assets/images/banner/toolsCover.png"),
			list:[
				{id:1,title:'Excellent automatic identification software'},
				{id:2,title:'Operational trading aids'},
				{id:3,title:'Automatically monitor market dynamics and trends'}
			],
			newList:[
				{id:1,img:require("@/assets/images/banner/tools01.jpg"),title:"Top performance, powerful charts",desc:"With more than 80 indicators, 50 drawing tools, 14 periods, and rich customization features, use multiple simultaneous charts in a single layout and apply multiple timeframe analysis."},
				{id:2,img:require("@/assets/images/banner/tools02.jpg"),title:"Don't miss any trading updates",desc:"Currency, index and commodity signals can be copied more efficiently. Using the Trade Copier, it is possible to track signal entries, exits, partial closures, half closures, changes in SL and TP, etc. Execute in real time without missing any trading opportunities."},
				{id:3,img:require("@/assets/images/banner/tools03.jpg"),title:"Effective Risk Management, Efficient Multiple Take Profit",desc:"Saving you time, money and effort, connecting to your account in minutes. When there are multiple Take Profits in a signal, multiple positions are opened for each Take Profit value and the Stop Loss is adjusted accordingly."}
			]
		},
		support:{
			title:"Support when you need it",
			list:[
				{id:1,img:require("@/assets/images/img/support01.png"),title:"Round-the-clock support",desc:"24 hours a day <br/>From Monday to Friday "},
				{id:2,img:require("@/assets/images/img/support02.png"),title:"Interactive education",desc:"Strengthen your trading skills and knowledge <br/>Ask questions and get the answers you need "},
				{id:3,img:require("@/assets/images/img/support03.png"),title:"Real-time trading signals",desc:"Clear entry and exit points<br/>Innovative FOREX trade ideas<br/>New strategies and daily analysis "},
			]
		},
		indexSeven:{
			title:"Our Products",
			list:[
				{id:1,price:"75",originalPrice:'',desc:"3 Months Membership",button:"Chooce Plan",list:[
					{id:1,title:"Trading with HuiPro Radar Indicator",type:true},
					{id:2,title:"Analyst Live Teaching",type:true},
					{id:3,title:"Daily market overview",type:true},
					{id:4,title:"Short term strategy",type:false},
					{id:5,title:"Long term strategy",type:false},
					{id:6,title:"4-7 target strategies",type:false},
					{id:7,title:"4000+pips per month",type:false},
					{id:8,title:"Online Customer Service",type:false},
					{id:9,title:"Management Tools",type:false},
					{id:10,title:"Real-time chat support",type:false}
				]},
				{id:2,price:"105",originalPrice:'',desc:"6 Months Membership",button:"Chooce Plan",list:[
					{id:1,title:"Trading with HuiPro Radar Indicator",type:true},
					{id:2,title:"Analyst Live Teaching",type:true},
					{id:3,title:"Daily market overview",type:true},
					{id:4,title:"Short term strategy",type:false},
					{id:5,title:"Long term strategy",type:false},
					{id:6,title:"4-7 target strategies",type:false},
					{id:7,title:"4000+pips per month",type:false},
					{id:8,title:"Online Customer Service",type:false},
					{id:9,title:"Management Tools",type:false},
					{id:10,title:"Real-time chat support",type:false}
				]},
				{id:3,price:"170",originalPrice:'',desc:"12 Months Membership",button:"Chooce Plan",list:[
					{id:1,title:"Trading with HuiPro Radar Indicator",type:true},
					{id:2,title:"Analyst Live Teaching",type:true},
					{id:3,title:"Daily market overview",type:true},
					{id:4,title:"Short term strategy",type:false},
					{id:5,title:"Long term strategy",type:false},
					{id:6,title:"4-7 target strategies",type:false},
					{id:7,title:"4000+pips per month",type:false},
					{id:8,title:"Online Customer Service",type:false},
					{id:9,title:"Management Tools",type:false},
					{id:10,title:"Real-time chat support",type:false}
				]},
			]
		}
	},
	chiefAnalyst:{
		banner:{
			title:"Chief Analyst Live",
			desc:"Global Chinese brand real-time trading live"
		},
		teacherTitle:"Teacher's introduction",
		teacherList:[
			{id:1,title:'k-God',desc:"Since 2008 into the trading market, through the foreign exchange, stock market, futures and other markets in-depth study, summarize the law of price fluctuations, to build a set of trend-following trading system - K-bit method. K-bit method is committed to capturing the starting point of the trend, holding positions waiting for the trend of the profit, suitable for short-medium and long-term trading trend friends.",img:require("@/assets/images/banner/teacher001.png")},
			{id:2,title:'Supervise',desc:"16 years of experience in international financial markets. For international futures, foreign exchange market, stock market securities have in-depth research. Has maintained 37 consecutive EIA data analysis completely correct trading record, to help the majority of traders from the financial beginner to become a master of the market transactions. Using the Martin trading method, he is able to steadily capture the trading opportunities in each market, continuously accumulate funds, and complete the dream of doubling the trading capital.",img:require("@/assets/images/banner/teacher002.png")},
			{id:3,title:'commander',desc:"Entered the investment market in 2010, with wave theory as the mainstream analysis system, starting from macro fundamentals, technical aspects of pattern identification, looking for pricing unreasonable opportunities from the market, to capture the value of the return of the top and bottom of the gains. Believes in the concept of no rules in the market and rules in the mind. Original 'cordon sanitaire trading' trend trading method, committed to the minimum risk to win the maximum profit, the correct judgement requires patience and perseverance.",img:require("@/assets/images/banner/teacher003.png")},
		]
	},
	indicatorAnalyst:{
		banner:{
			title:'Indictor Education Iive',
			desc:"Global leading brand financier-fintech real-time  live  trading studio since 2016"
		},
		title:'Our advantage',
		img:require("@/assets/images/banner/indicatorBanner.png"),
		list:[
			{id:1,title:"Real time market analysis by chief trader"},
			{id:2,title:"Explain indicator techniques"},
			{id:3,title:"Judging transaction trends"},
			{id:4,title:"Community online interaction"}
		]
	},
	comment:[
		{id:1,title:"julius ngowijulius",img:require("@/assets/images/index/indexBg01.png"),time:"13th june 2020",star:4,desc:"ust payed year subscription and on 3 days it was payed withtake profit. The best team in forex all respect from me  slenas hit team in forex all respect from me  slenas hi"},
		{id:2,title:"julius ngowijulius",img:require("@/assets/images/index/indexBg02.png"),time:"13th june 2020",star:4,desc:"ust payed year subscription and on 3 days it was payed withtake profit. The best team in forex all respect from me  slenas hit team in forex all respect from me  slenas hi"},
		{id:3,title:"julius ngowijulius",img:require("@/assets/images/index/indexBg03.png"),time:"13th june 2020",star:4,desc:"ust payed year subscription and on 3 days it was payed withtake profit. The best team in forex all respect from me  slenas hit team in forex all respect from me  slenas hi"},
	],
	pay:[
		{id:1,title:"First name",required:true,type:'text',value:'',valueId:''},
		{id:2,title:"Last name",required:true,type:'text',value:'',valueId:''},
		{id:3,title:"Country/Region",required:true,type:'select',value:'',valueId:''},
		{id:4,title:"Street address",required:false,type:'text',value:'',valueId:''},
		{id:5,title:"Town/City",required:false,type:'text',value:'',valueId:''},
		{id:6,title:"ZIP Code",required:false,type:'text',value:'',valueId:''},
		{id:7,title:"Phone",required:true,type:'text',value:'',valueId:''},
		{id:8,title:"Email address",required:true,type:'text',value:'',valueId:''},
	],
	paymentMethods:{
		title:"Payment methods",
		list:[
			{id:1,title:"Credit Card/Debit Card",desc:"Pay with your credit/debit card",show:false,list:[
				{id:1,title:"Name",required:true,type:'text',value:'',valueId:''},
				{id:2,title:"Card Number",required:true,type:'text',value:'',valueId:''},
				{id:3,title:"Expiry Date",required:true,type:'date',value:'',valueId:''},
				{id:4,title:"Card Code (CVC)",required:true,type:'text',value:'',valueId:''},
			]},
			{id:2,title:"PayPal",desc:"",show:true,list:[]},
			{id:3,title:"Airwallex",desc:"",show:true,list:[]}
		]
	},
	faq:{
		title:"FAQ",
		desc:"",
		list:[
			{id:1,title:"",desc:"",list:[
				{id:1,title:"How can I receive your signals?",desc:"You can get our signals by means of Telegram or WhatsApp application or through email.To receive our signals immediately with sound notifications, we advise you to use the Telegram or WhatsApp app.",show:false},
				{id:2,title:"At what time do you send your signals ?",desc:"We send signals during market trading sessions whenever a suitable opportunity arises.",show:false},
				{id:3,title:"What are Forex Trading Signals?",desc:"Our signals are very easy to implement and can likewise be followed by everyone who needs it without any hassle.<br/>Our team of experts will examine and focus on the most advantageous trading opportunities, and once suitable, they will offer them to you as a possibility to open an entry to buy or sell. Additionally, there is minimal delay in executing the trades. Our signals provide the following data:<br/>The most suitable entry point<br/>The most suitable closing point<br/>The most suitable breed to choose",show:false},
				{id:4,title:"I already have a trading account. How can I benefit from your site?",desc:"You can utilize the record you have just made and exchange with the assistance of the intermediary who has helped you as of not long ago. You are for all intents and purposes ready to utilize our Signals and exchanging thoughts while working with each and every broker you pick.",show:false},
				{id:5,title:"Why should I open an account in your recommended broker?",desc:"One of the most significant advances, perhaps the most significant one, really, is to pick the correct broker for you. We will likely guarantee security for our customers and their assets. To achieve this, we closely collaborate with the broker. This merchant is a main internet exchanging supplier. Our numerous long periods of involvement and various tests drove us to the end that this broker demonstrates the best terms and conditions that line up with our exchange signals.",show:false},
			]}
		]
	},
	payInfo:[
		{id:1,key:"orderNo",show:true,title:"Merchandise order：",value:""},
		{id:2,key:"amt",show:true,title:"Amount paid：",value:""},
		{id:3,key:"goodsName",show:true,title:"Trade name：",value:""},
		{id:4,key:"",show:false,title:"Order quantity：",value:""},
		{id:5,key:"channelOrderNo",show:true,title:"Pay an order：",value:""},
		{id:6,key:"payWay",show:true,title:"Payment method：",value:""},
		{id:7,key:"createTime",show:true,title:"Purchase time：",value:""},
		{id:8,key:"userName",show:true,title:"Name：",value:""},
		{id:9,key:"",show:false,title:"Street address：",value:""},
		{id:10,key:"",show:false,title:"Town/City：",value:""},
		{id:11,key:"",show:false,title:"ZIP Code ：",value:""},
		{id:12,key:"phone",show:true,title:"Phone ：",value:""},
		{id:13,key:"email",show:true,title:"Email address：",value:""},
		{id:14,key:"",show:false,title:"Order notes ：",value:""},
	]
}
