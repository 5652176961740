module.exports = {
	footer:{
		footer:'注意:交易可能导致经济损失。仅用您准备损失的资金进行交易，您必须认识到，由于您无法控制的因素，您可能会损失交易账户中的所有资金。我们不知道您交易的资金水平或您在每笔交易中承担的风险水平。您必须做出自己的财务决定，我们对由此产生的收益或损失不承担任何责任。',
		leftTitle:'XC Markets',
		leftDesc:'我们随时愿意讨论您的项目并提高您的在线形象。',
		emailTitle:'发送电子邮件至',
		emailDesc:'xcprofx@xcprofx.com',
		whatsTitle:'WhatsApp',
		whatsDesc:'0927 6277 28525',
		telegram:"https://t.me/XC600broker01",
		aboutUs:'关于我们',
		aboutDesc:'XC Markets是一个教育网站和交换外汇信息的平台。本网站上包含的所有信息均为作者个人观点或信念。这些数据在任何意义上都不是推荐或财务建议。'
	},
	login: {
	    signUp:"注册",
	    signIn: "登录",
		signOut:"登出",
		submit:'提交',
		tips:'账号审核中',
		signtips:'确认退出登录?'
	},
	language:{
		en:'英文',
		zh:'中文',
		home:'首页'
	},
	buttom:{
		readMore:"查看更多",
		removeObstacles:"开通",
		reviews:"评论",
		payLeft:"支付表单",
		payRight:"你的订单",
		product:"商品",
		subtotal:"小计",
		number:"数量",
		total:"总计",
		policy:"您的个人数据将用于处理您的订单。在整个网站上支持您的体验，并用于我们的隐私政策中描述的其他目的",
		condition:"我已阅读并同意网站条款和条件",
		placeOrder:"订购",
		choocePlan:"选择计划",
		moreVideo:"更多视频请点击",
		canpay:"取消支付！",
		paySuc:"支付成功！",
		payFail:"支付失败",
		back:"返回",
		tips:"请输入",
		tipss:"请选择",
	},
	eMsg:{
		loading:"加载中"
	},
	// {id:1,title:'首席分析师直播',src:'/chiefAnalyst',show:false},{id:2,title:'指标教学直播',src:'/indicatorAnalyst',show:true}
	nav:[
		{id:1,title:'首页',src:'/',list:[],show:true},
		{id:2,title:'最新结果',src:'#latestResults',list:[],show:true},
		{id:3,title:'我的工具',src:'#ourTools',list:[],show:true},
		{id:4,title:'产品',src:'#Membership',list:[],show:true},
		{id:5,title:'观看直播',src:'/indicatorAnalyst',list:[],show:true},
		{id:6,title:'金融课程',src:'#financeCourse',list:[],show:true},
		{id:7,title:'推荐的broker',src:'https://www.ychpro.com',list:[],show:false},
		{id:8,title:'关于我们',src:'#footer',list:[],show:true},
		{id:9,title:'问题解答',src:'#answerQuestions',list:[],show:true}
	],
	index:{
		banner:{
			title:"XC Markets Service",
			subtitle:'Education for finance and fintech',
			src:require("@/assets/images/banner/indexBanner.png")
		},
		indexOne:{
			subTitle:'首先使用客户端',
			title:'金融培训',
			desc:'旨在提高您的金融知识，并帮助您提高您的交易技能，让您更自信的进行交易！无论您是初学者还是经验丰富的交易者，我们经验丰富的市场分析师培训、短视频课程教学将为您讲解金融知识。我们致力于陪伴在您的金融知识学习成长路上的每一步，通过提供有价值的金融教育，为您的金融交易奠定基础。'
		},
		indexTwo:{
			subTitle:'首先使用客户端',
			title:'在线直播',
			desc:'全球唯一一个全天10小时分析师在线视频直播，带您解读国际经济市场重大新闻和事件，分析热门产品交易策略。加入我们在线视频直播培训，首席分析师将在线分享他们的交易知识，以及为您答疑解惑。<br/>全天24小时实时指标直播，获得优质的技术支持，帮助您发掘成功趋势交易的秘密，在线教学，轻松掌握。'
		},
		indexThree:{
			subTitle:'首先使用客户端',
			title:'交易工具',
			desc:'出色的自动识别软件，提升您的交易体验。雷达指标是一种用于技术分析以及交易的线上图表软件，提供一系列可操作的交易辅助工具，精准研判，自动监视市场动态和趋势，让您在新的市场趋势形成时收到信号。'
		},
		indexFour:{
			title:'十一月特惠'
		},
		indexFive:{
			title:'最新结果',
			titleList:[
				{id:1,title:"货币对"},
				{id:2,title:"买卖"},
				{id:3,title:"关闭时间"},
				{id:4,title:"达到止盈止损"},
				{id:5,title:"利润损失"}
			],
			list:[
				{id:1,currencyPair:"欧元/CAD",view:"视图",buySell:"卖",ClosedAt:"1.5450",hit:"TP-2",proftLoss:"+ 50 点"},
				{id:2,currencyPair:"瑞士法郎/日元",view:"观点",buySell:"卖",ClosedAt:"116.62",hit:"TP-2",proftLoss:"+ 100 点"},
				{id:3,currencyPair:"欧元/美元",view:"观点",buySell:"卖",ClosedAt:"1.2077",hit:"TP-2",proftLoss:"+ 50 点"},
				{id:4,currencyPair:"新西兰元/瑞士法郎",view:"观点",buySell:"卖",ClosedAt:"0.6250",hit:"TP-2",proftLoss:"+ 50 点"}
			]
		},
		indexSix:{
			title:'我的工具',
			desc:"Huipro雷达指标系统 <br/> 然后进入我们先进的交易工具，抓住时机",
			cover:require("@/assets/images/banner/toolsCover.png"),
			list:[
				{id:1,title:'优秀的自动识别软件'},
				{id:2,title:'操作交易辅助工具'},
				{id:3,title:'自动监控市场动态和趋势'}
			],
			newList:[
				{id:1,img:require("@/assets/images/banner/tools01.jpg"),title:"顶级性能，强大的图表",desc:"有80多个指标、50个绘图工具、14个周期和丰富的自定义功能，可以在单个布局中同时使用多个图表，并应用多个时间框架分析。"},
				{id:2,img:require("@/assets/images/banner/tools02.jpg"),title:"丰富的技术指标",desc:"绘制趋势线、弧线和轴心点、烛台图案，并自动识别支撑和阻力突破。使用指标、覆盖图和工具做出更好的交易决策。准确的趋势检测。"},
				{id:3,img:require("@/assets/images/banner/tools03.jpg"),title:"灵活提醒，智能交易",desc:"为价格变动设置自定义警报。享受人工智能提供的独家交易策略。不再碰交易机会。一眼就能发现潜在的交易机会。"}
			]
		},
		support:{
			title:"Support when you need it",
			list:[
				{id:1,img:require("@/assets/images/img/support01.png"),title:"Round-the-clock support",desc:"24 hours a day <br/>From Monday to Friday "},
				{id:2,img:require("@/assets/images/img/support02.png"),title:"Interactive education",desc:"Strengthen your trading skills and knowledge <br/>Ask questions and get the answers you need "},
				{id:3,img:require("@/assets/images/img/support03.png"),title:"Real-time trading signals",desc:"Clear entry and exit points<br/>Innovative FOREX trade ideas<br/>New strategies and daily analysis "},
			]
		},
		indexSeven:{
			title:"我们的产品",
			list:[
				{id:1,price:"75",originalPrice:'',desc:"3个月会员资格",button:"选择计划",list:[
					{id:1,title:"利用HuiPro雷达指标交易",type:true},
					{id:2,title:"分析师直播教学",type:true},
					{id:3,title:"每日市场分析",type:true},
					{id:4,title:"短期交易信号策略",type:false},
					{id:5,title:"长期交易信号策略",type:false},
					{id:6,title:"4-7 个目标策略",type:false},
					{id:7,title:"每月4000+盈利",type:false},
					{id:8,title:"在线客服服务",type:false},
					{id:9,title:"管理工具",type:false},
					{id:10,title:"实时聊天支持",type:false}
				]},
				{id:2,price:"105",originalPrice:'',desc:"6 个月会员资格",button:"选择计划",list:[
					{id:1,title:"利用HuiPro雷达指标交易",type:true},
					{id:2,title:"分析师直播教学",type:true},
					{id:3,title:"每日市场分析",type:true},
					{id:4,title:"短期交易信号策略",type:false},
					{id:5,title:"长期交易信号策略",type:false},
					{id:6,title:"4-7 个目标策略",type:false},
					{id:7,title:"每月4000+盈利",type:false},
					{id:8,title:"在线客服服务",type:false},
					{id:9,title:"管理工具",type:false},
					{id:10,title:"实时聊天支持",type:false}
				]},
				{id:3,price:"170",originalPrice:'',desc:"12 个月会员资格",button:"选择计划",list:[
					{id:1,title:"利用HuiPro雷达指标交易",type:true},
					{id:2,title:"分析师直播教学",type:true},
					{id:3,title:"每日市场分析",type:true},
					{id:4,title:"短期交易信号策略",type:false},
					{id:5,title:"长期交易信号策略",type:false},
					{id:6,title:"4-7 个目标策略",type:false},
					{id:7,title:"每月4000+盈利",type:false},
					{id:8,title:"在线客服服务",type:false},
					{id:9,title:"管理工具",type:false},
					{id:10,title:"实时聊天支持",type:false}
				]},
			]
		}
	},
	chiefAnalyst:{
		banner:{
			title:'首席分析师直播',
			desc:"全球中文品牌实时交易直播"
		},
		teacherTitle:"导师介绍",
		teacherList:[
			{id:1,title:'K神',desc:"自2008年进入交易市场，通过对外汇、股市、期货等市场的深入研究，总结价格波动规律，构建出一套趋势跟随交易系统--K位战法。K位战法致力于捕捉趋势起点，持仓等待趋势利润，适用于短中长线交易做趋势的朋友。",img:require("@/assets/images/banner/teacher01.png")},
			{id:2,title:'督导',desc:"16年的的国际金融市场从业经验。针对国际期货，外汇市场、股市证券有眷深入的研究。曾保持连续37次EIA数据分析完全正确的交易记录，帮助广大交易者从金融初学者成为市场交易高手。利用马丁交易法，能够稳定抓取每一次市场的交易机会，不断积累资金，完成交易资金翻倍的梦想。",img:require("@/assets/images/banner/teacher02.png")},
			{id:3,title:'司令',desc:"2010年进入投资市场，以波浪理论为主流分析体系，从宏观基本面着手，技术面做形态识别，从市场中寻找定价不合理的机会，捕捉价值回归的摸顶抄底收益。信奉 市场无规则，心中有规则的理念。独创《警戒线交易》趋势交易法则，致力于用最少风险博取最大利润，正确判断耐心坚守。",img:require("@/assets/images/banner/teacher03.png")},
		]
	},
	indicatorAnalyst:{
		banner:{
			title:'指标教学直播',
			desc:"自 2016 年以来，全球领先品牌金融家-金融科技实时直播交易平台"
		},
		title:'我们的优势',
		img:require("@/assets/images/banner/indicatorBanner.png"),
		list:[
			{id:1,title:"首席交易员实时市场分析"},
			{id:2,title:"讲解指标技巧"},
			{id:3,title:"判断交易趋势"},
			{id:4,title:"社区在线互动"}
		]
	},
	comment:[
		{id:1,title:"七月",img:require("@/assets/images/index/indexBg01.png"),time:"2023年11月13日",star:4,desc:"刚刚支付了一年的订阅费，并在3天内支付了利润。外汇最好的球队都尊重我slenas外汇命中队都尊重我slenas嗨"},
		{id:2,title:"七月",img:require("@/assets/images/index/indexBg02.png"),time:"2023年11月13日",star:4,desc:"刚刚支付了一年的订阅费，并在3天内支付了利润。外汇最好的球队都尊重我slenas外汇命中队都尊重我slenas嗨"},
		{id:3,title:"七月",img:require("@/assets/images/index/indexBg03.png"),time:"2023年11月13日",star:4,desc:"刚刚支付了一年的订阅费，并在3天内支付了利润。外汇最好的球队都尊重我slenas外汇命中队都尊重我slenas嗨"},
	],
	pay:[
		{id:1,title:"名",required:true,type:'text',value:'',valueId:''},
		{id:2,title:"姓",required:true,type:'text',value:'',valueId:''},
		{id:3,title:"国家/地区",required:true,type:'select',value:'',valueId:''},
		{id:4,title:"街道地址",required:false,type:'text',value:'',valueId:''},
		{id:5,title:"市政/城市",required:false,type:'text',value:'',valueId:''},
		{id:6,title:"邮政编码",required:false,type:'text',value:'',valueId:''},
		{id:7,title:"电话",required:true,type:'text',value:'',valueId:''},
		{id:8,title:"电子邮箱地址",required:true,type:'text',value:'',valueId:''},
	],
	paymentMethods:{
		title:"付款方式",
		list:[
			{id:1,title:"信用卡/借记卡",desc:"使用信用卡/借记卡支付",show:false,list:[
				{id:1,title:"姓名",required:true,type:'text',value:'',valueId:''},
				{id:2,title:"卡号",required:true,type:'text',value:'',valueId:''},
				{id:3,title:"到期日",required:true,type:'date',value:'',valueId:''},
				{id:4,title:"卡代码（CVC）",required:true,type:'text',value:'',valueId:''},
			]},
			{id:2,title:"PayPal",desc:"",show:true,list:[]},
			{id:3,title:"Airwallex",desc:"",show:true,list:[]}
		]
	},
	faq:{
		title:"问题解答",
		desc:"",
		list:[
			{id:1,title:"",desc:"",list:[
				{id:1,title:"我怎样才能接收到你们的信号？",desc:"您可以通过Telegram应用程序或WhatsApp获取我们的信息。<br/>为了通过声音通知立即获得我们的客服，我们指导您使用 Telegram、WhatsApp应用程序。",show:false},
				{id:2,title:"您在什么时间发送信号？",desc:"每当有交流机会时，我们都会行情交易时段发送信息。",show:false},
				{id:3,title:"什么是外汇交易信号？",desc:"我们的信号非常易于实现，并且同样可以被每个需要的人毫无麻烦地遵循。<br/>我们的专家团队会检查并关注最有利的交易机会，一旦合适，他们就会将其作为开启进场买卖的可能性提供给您。此外，在进入安排时几乎没有推迟。我们的信号提供以下数据：<br/>最合适的进场点位<br/>最合适的平仓点位<br/>最合适的品种选择",show:false},
				{id:4,title:"我已经有一个交易账户。我怎样才能从你们的网站中受益？",desc:"您可以利用您刚刚制作的记录，并在不久前帮助您的中介人的协助下进行交换。出于所有意图和目的，您已准备好利用我们的信号并在与您选择的每一位经纪人合作时交换想法。",show:false},
				{id:5,title:"我为什么要在你们推荐的经纪商开户？",desc:"最重要的进步之一，也许是最重要的进步，真的，就是为您选择正确的经纪人。我们可能会保证客户及其资产的安全。为了实现这一目标，我们与指定经纪商密切合作。该商家是主要的互联网交换供应商。我们无数次的长期参与和各种测试促使我们最终证明，该经纪人展示了符合我们交易信号的最佳条款和条件。",show:false},
			]}
		]
	},
	payInfo:[
		{id:1,key:"orderNo",show:true,title:"商品订单：",value:""},
		{id:2,key:"amt",show:true,title:"支付金额：",value:""},
		{id:3,key:"goodsName",show:true,title:"商品名称：",value:""},
		{id:4,key:"",show:false,title:"订单数量：",value:""},
		{id:5,key:"channelOrderNo",show:true,title:"支付订单：",value:""},
		{id:6,key:"payWay",show:true,title:"付款方式：",value:""},
		{id:7,key:"createTime",show:true,title:"购买时间：",value:""},
		{id:8,key:"userName",show:true,title:"姓名：",value:""},
		{id:9,key:"",show:false,title:"街道地址：",value:""},
		{id:10,key:"",show:false,title:"城镇：",value:""},
		{id:11,key:"",show:false,title:"邮政编码：",value:""},
		{id:12,key:"phone",show:true,title:"联系电话：",value:""},
		{id:13,key:"email",show:true,title:"电子邮件地址：",value:""},
		{id:14,key:"",show:false,title:"订单备注：",value:""},
	]
}
