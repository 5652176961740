<template>
	<div id="payment">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- 内容 -->
		<div class="paymentBox">
			<div class="paymentLeft">
				<div class="paymenttitle">{{ $t('buttom.payLeft') }}</div>
				<div class="formBox">
					<div class="sameInput" :class="index<2?'sameInputHalf':''"
					v-for="(item,index) in payList" :key="index" v-if="item.required">
						<div class="title">
							<span>{{item.title}}</span>
							<img v-if="item.required" src="@/assets/images/img/pay02.png"/>
						</div>
						<div class="sameSelect" v-if="item.type == 'select'" @click="selectFun()">
							<span>{{item.value}}</span>
							<img src="@/assets/images/img/pay01.png"/>
						</div>
						<input class="sameinput" v-if="item.type != 'select'" type="text" placeholder="" v-model="item.value">
						<div class="sameFormInputSelect" :class="(isSelect && index == 2?'sameFormInputSelectMore':'')">
							<div class="sameCountry" @click="chooseFun(cindex)" v-for="(item,cindex) in countryList" :key="cindex">{{item.name}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="paymentRight">
				<div class="paymenttitle">{{ $t('buttom.payRight') }}</div>
				<div class="paymentRightBox">
					<div class="paymentRightTitle">{{ $t('buttom.product') }}</div>
					<div class="option">
						<div>{{goodsInfo.categoryName}}</div>
					</div>
					<div class="option">
						<div>{{goodsInfo.name}}</div>
						<span>${{goodsInfo.amt}}</span>
					</div>
					<!-- <div class="option subtotal">
						<div>{{ $t('buttom.number') }}</div>
						<span>{{num}}</span>
					</div> -->
					<div class="option total">
						<div>{{ $t('buttom.total') }}</div>
						<span>${{goodsInfo.amt * num}}</span>
					</div>
					<div class="paymentMethods">
						<div class="paymentMethodsTitle">{{ $t('paymentMethods.title') }}</div>
						<div class="payList" v-for="(item,index) in cardList" :key="index" v-if="item.show">
							<div class="payListTitle" @click="currentFun(index)">
								<img class="payListTitleChoose" :src="current == index?require('@/assets/images/img/pay07.png'):require('@/assets/images/img/pay08.png')"/>
								<div class="payListTitleRight">
									<div>{{item.title}}</div>
									<span v-if="item.desc">{{item.desc}}</span>
								</div>
								<img class="payListTitlePay" v-if="index == 1" src="@/assets/images/img/pay05.png"/>

							</div>
							<div class="formBox" v-if="current == index">
								<div class="sameInput" :class="sindex>1?'sameInputHalf':''"
								v-for="(sitem,sindex) in item.list" :key="sindex">
									<div class="title">
										<span>{{sitem.title}}</span>
										<img v-if="sitem.required" src="@/assets/images/img/pay02.png"/>
									</div>
									<div class="sameCardInput">
										<input :type="sitem.type" placeholder="" v-model="sitem.value">
										<img v-if="sindex == 1" src="@/assets/images/img/pay06.png"/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="payDesc">{{ $t('buttom.policy') }}</div>
					<div class="payAgree" @click="agree = !agree">
						<img class="payListTitleChoose" :src="agree?require('@/assets/images/img/pay04.png'):require('@/assets/images/img/pay03.png')"/>
						<span>{{ $t('buttom.condition') }}</span>
					</div>
<!--					<div class="placeOrderone" v-if="current == 1" @click="submitFun">
						<img src="@/assets/images/img/pay005.png"/>
					</div>
					<div class="placeOrder"  v-if="current == 0" @click="submitFun">{{ $t('buttom.placeOrder') }}</div>-->
					<div v-if="current == 1">
						<div id="paypal-button-container"></div>
						<p id="result-message"></p>
					</div>
					<div class="placeOrder airwallex" v-if="current == 2" @click="submitFun">
						<img src="@/assets/images/img/airwallex.png"/>
					</div>
				</div>
			</div>
		</div>
		<!--  -->
	</div>
</template>

<script>
	export default {
		name: 'payment',
		data() {
			return {
				agree:true,
				index:0,
				info:{},
				current:1,
				id:0,
				num:0,
				goodsInfo:{},
				payList:[],
				payInfo:{
					goodsId:'',
					payWay:'payPal',
					userName:'',
					country:'',
					address:'',
					city:'',
					zipCode:'',
					phone:'',
					email:'',
				},
				cardInfo:{
					orderNo:"",
					name:"",
					number:"",
					securityCode:"",
					expiry:"",
				},
				paypalUrl:'',
				cardList:[],
				isSelect:false,
				countryList: [
					{name:"中国 (China)",areaCode:"86"},
					{name:"中国香港 (Hong Kong)",areaCode:"852"},
					{name:"中国澳門 (Macau)",areaCode:"853"},
					{name:"中国台湾 (Taiwan)",areaCode:"886"},
					{name:"日本 (Japan)",areaCode:"81"},
					{name:"韩国 (South Korea)",areaCode:"82"},
					{name:"美国 (USA or Canada)",areaCode:"1"},
					{name:"阿富汗 (Afghanistan)",areaCode:"93"},
					{name:"阿尔巴尼亚 (Albania)",areaCode:"355"},
					{name:"阿尔及利亚 (Algeria)",areaCode:"213"},
					{name:"萨摩亚 (American Samoa)",areaCode:"684"},
					{name:"安道尔共和国 (Andorra)",areaCode:"376"},
					{name:"安哥拉 (Angola)",areaCode:"244"},
					{name:"安圭拉岛 (Anguilla)",areaCode:"1264"},
					{name:"南极洲 (Antarctica)",areaCode:"672"},
					{name:"安提瓜和巴布达 (Antigua and Barbuda)",areaCode:"1268"},
					{name:"阿根廷 (Argentina)",areaCode:"54"},
					{name:"亚美尼亚 (Armenia)",areaCode:"374"},
					{name:"阿鲁巴 (Aruba)",areaCode:"297"},
					{name:"澳大利亚 (Australia)",areaCode:"61"},
					{name:"奥地利 (Austria)",areaCode:"43"},
					{name:"阿塞拜疆 (Azerbaijan)",areaCode:"994"},
					{name:"巴哈马 (Bahamas)",areaCode:"1242"},
					{name:"巴林 (Bahrain)",areaCode:"973"},
					{name:"孟加拉国 (Bangladesh)",areaCode:"880"},
					{name:"巴巴多斯 (Barbados)",areaCode:"1246"},
					{name:"白俄罗斯 (Belarus)",areaCode:"375"},
					{name:"比利时 (Belgium)",areaCode:"32"},
					{name:"伯利兹城 (Belize)",areaCode:"501"},
					{name:"贝宁 (Benin)",areaCode:"229"},
					{name:"百慕大 (Bermuda)",areaCode:"1441"},
					{name:"不丹 (Bhutan)",areaCode:"975"},
					{name:"玻利维亚 (Bolivia)",areaCode:"591"},
					{name:"波斯尼亚和黑塞哥维那 (Bosnia and Herzegovina)",areaCode:"387"},
					{name:"博茨瓦纳 (Botswana)",areaCode:"267"},
					{name:"巴西 (Brazil)",areaCode:"55"},
					{name:"英属印度洋领地 (British Indian Ocean Territory)",areaCode:"246"},
					{name:"文莱达鲁萨兰国 (Brunei Darussalam)",areaCode:"673"},
					{name:"保加利亚 (Bulgaria)",areaCode:"359"},
					{name:"布基纳法索 (Burkina Faso)",areaCode:"226"},
					{name:"布隆迪 (Burundi)",areaCode:"257"},
					{name:"柬埔寨 (Cambodia)",areaCode:"855"},
					{name:"喀麦隆 (Cameroon)",areaCode:"237"},
					{name:"佛得角 (Cape Verde)",areaCode:"238"},
					{name:"开曼群岛 (Cayman Islands)",areaCode:"1345"},
					{name:"中非共和国 (Central African Republic)",areaCode:"236"},
					{name:"乍得 (Chad)",areaCode:"235"},
					{name:"智利 (Chile)",areaCode:"56"},
					{name:"圣延岛 (Christmas Island)",areaCode:"61"},
					{name:"科科斯群岛 (Cocos (Keeling) Islands)",areaCode:"61"},
					{name:"哥伦比亚(Colombia)",areaCode:"57"},
					{name:"科摩罗 (Comoros)",areaCode:"269"},
					{name:"刚果 (Congo)",areaCode:"242"},
					{name:"刚果民主共和国(Congo,The Democratic Republic Of The)",areaCode:"243"},
					{name:"库克群岛 (Cook Islands)",areaCode:"682"},
					{name:"哥斯达黎加 (Costa Rica)",areaCode:"506"},
					{name:"科特迪瓦 (Cote D Ivoire)",areaCode:"225"},
					{name:"克罗地亚 (Croatia)",areaCode:"385"},
					{name:"古巴 (Cuba)",areaCode:"53"},
					{name:"塞浦路斯 (Cyprus)",areaCode:"357"},
					{name:"捷克 (Czech Republic)",areaCode:"420"},
					{name:"丹麦 (Denmark)",areaCode:"45"},
					{name:"吉布提 (Djibouti)",areaCode:"253"},
					{name:"多米尼克国 (Dominica)",areaCode:"1767"},
					{name:"多米尼加共和国 (Dominican Republic)",areaCode:"1849"},
					{name:"东帝汶 (East Timor)",areaCode:"670"},
					{name:"厄瓜多尔 (Ecuador)",areaCode:"593"},
					{name:"埃及 (Egypt)",areaCode:"20"},
					{name:"萨尔瓦多 (El Salvador)",areaCode:"503"},
					{name:"赤道几内亚 (Equatorial Guinea)",areaCode:"240"},
					{name:"爱沙尼亚 (Estonia)",areaCode:"372"},
					{name:"埃塞俄比亚 (Ethiopia)",areaCode:"251"},
					{name:"福克兰群岛(Falkland Islands (Malvinas))",areaCode:"500"},
					{name:"法罗群岛 (Faroe Islands)",areaCode:"298"},
					{name:"斐济 (Fiji)",areaCode:"679"},
					{name:"芬兰 (Finland)",areaCode:"358"},
					{name:"法国 (France)",areaCode:"33"},
					{name:"法国大都会 (France Metropolitan)",areaCode:"33"},
					{name:"法属圭亚那 (French Guiana)",areaCode:"594"},
					{name:"法属玻里尼西亚 (French Polynesia)",areaCode:"689"},
					{name:"加蓬 (Gabon)",areaCode:"241"},
					{name:"冈比亚 (Gambia)",areaCode:"220"},
					{name:"格鲁吉亚 (Georgia)",areaCode:"995"},
					{name:"德国 (Germany)",areaCode:"49"},
					{name:"加纳 (Ghana)",areaCode:"233"},
					{name:"直布罗陀 (Gibraltar)",areaCode:"350"},
					{name:"希腊 (Greece)",areaCode:"30"},
					{name:"格陵兰 (Greenland)",areaCode:"45"},
					{name:"格林纳达 (Grenada)",areaCode:"1473"},
					{name:"瓜德罗普岛 (Guadeloupe)",areaCode:"590"},
					{name:"关岛 (Guam)",areaCode:"1671"},
					{name:"危地马拉 (Guatemala)",areaCode:"502"},
					{name:"几内亚 (Guinea)",areaCode:"224"},
					{name:"几内亚比绍 (Guinea-Bissau)",areaCode:"245"},
					{name:"圭亚那 (Guyana)",areaCode:"592"},
					{name:"海地 (Haiti)",areaCode:"509"},
					{name:"洪都拉斯 (Honduras)",areaCode:"504"},
					{name:"匈牙利 (Hungary)",areaCode:"36"},
					{name:"冰岛 (Iceland)",areaCode:"354"},
					{name:"印度 (India)",areaCode:"91"},
					{name:"印度尼西亚 (Indonesia)",areaCode:"62"},
					{name:"伊朗 (Iran (Islamic Republic of))",areaCode:"98"},
					{name:"伊拉克 (Iraq)",areaCode:"964"},
					{name:"爱尔兰 (Ireland)",areaCode:"353"},
					{name:"以色列 (Israel)",areaCode:"972"},
					{name:"意大利 (Italy)",areaCode:"39"},
					{name:"牙买加 (Jamaica)",areaCode:"1876"},
					{name:"约旦 (Jordan)",areaCode:"962"},
					{name:"哈萨克 (Kazakhstan)",areaCode:"7"},
					{name:"肯尼亚 (Kenya)",areaCode:"254"},
					{name:"科威特 (Kuwait)",areaCode:"965"},
					{name:"吉尔吉斯 (Kyrgyzstan)",areaCode:"996"},
					{name:"拉脱维亚 (Latvia)",areaCode:"371"},
					{name:"黎巴嫩 (Lebanon)",areaCode:"961"},
					{name:"莱索托 (Lesotho)",areaCode:"266"},
					{name:"利比里亚 (Liberia)",areaCode:"231"},
					{name:"利比亚 (Libyan Arab Jamahiriya)",areaCode:"218"},
					{name:"列支敦士登 (Liechtenstein)",areaCode:"423"},
					{name:"立陶宛 (Lithuania)",areaCode:"370"},
					{name:"卢森堡 (Luxembourg)",areaCode:"352"},
					{name:"马达加斯加 (Madagascar)",areaCode:"261"},
					{name:"马拉维 (Malawi)",areaCode:"265"},
					{name:"马来西亚 (Malaysia)",areaCode:"60"},
					{name:"马尔代夫 (Maldives)",areaCode:"960"},
					{name:"马里 (Mali)",areaCode:"223"},
					{name:"马尔他 (Malta)",areaCode:"356"},
					{name:"马提尼克岛 (Martinique)",areaCode:"596"},
					{name:"毛里塔尼亚 (Mauritania)",areaCode:"222"},
					{name:"毛里求斯(Mauritius)",areaCode:"230"},
					{name:"马约特 (Mayotte)",areaCode:"262"},
					{name:"墨西哥 (Mexico)",areaCode:"52"},
					{name:"密克罗尼西亚 (Micronesia)",areaCode:"691"},
					{name:"摩尔多瓦 (Moldova)",areaCode:"373"},
					{name:"摩纳哥 (Monaco)",areaCode:"377"},
					{name:"外蒙古 (Mongolia)",areaCode:"976"},
					{name:"黑山共和国 (Montenegro)",areaCode:"382"},
					{name:"蒙特塞拉特 (Montserrat)",areaCode:"1664"},
					{name:"摩洛哥 (Morocco)",areaCode:"212"},
					{name:"莫桑比克 (Mozambique)",areaCode:"258"},
					{name:"缅甸 (Myanmar)",areaCode:"95"},
					{name:"那米比亚 (Namibia)",areaCode:"264"},
					{name:"瑙鲁 (Nauru)",areaCode:"674"},
					{name:"尼泊尔 (Nepal)",areaCode:"977"},
					{name:"荷兰 (Netherlands)",areaCode:"31"},
					{name:"荷兰安的列斯群岛 (Netherlands Antilles)",areaCode:"599"},
					{name:"新喀里多尼亚 (New Caledonia)",areaCode:"687"},
					{name:"新西兰 (New Zealand)",areaCode:"64"},
					{name:"尼加拉瓜 (Nicaragua)",areaCode:"505"},
					{name:"尼日尔 (Niger)",areaCode:"227"},
					{name:"尼日利亚 (Nigeria)",areaCode:"234"},
					{name:"诺福克岛 (Norfolk Island)",areaCode:"6723"},
					{name:"朝鲜 (North Korea)",areaCode:"850"},
					{name:"北马里亚纳群岛 (Northern Mariana Islands)",areaCode:"1670"},
					{name:"挪威 (Norway)",areaCode:"47"},
					{name:"阿曼 (Oman)",areaCode:"968"},
					{name:"巴基斯坦 (Pakistan)",areaCode:"92"},
					{name:"帛琉 (Palau)",areaCode:"680"},
					{name:"巴勒斯坦 (Palestine)",areaCode:"970"},
					{name:"巴拿马 (Panama)",areaCode:"507"},
					{name:"巴布亚新几内亚 (Papua New Guinea)",areaCode:"675"},
					{name:"巴拉圭 (Paraguay)",areaCode:"595"},
					{name:"秘鲁 (Peru)",areaCode:"51"},
					{name:"菲律宾共和国 (Philippines)",areaCode:"63"},
					{name:"皮特凯恩岛 (Pitcairn)",areaCode:"64"},
					{name:"波兰 (Poland)",areaCode:"48"},
					{name:"葡萄牙 (Portugal)",areaCode:"351"},
					{name:"波多黎各 (Puerto Rico)",areaCode:"1787"},
					{name:"卡塔尔 (Qatar)",areaCode:"974"},
					{name:"留尼汪岛 (Reunion)",areaCode:"262"},
					{name:"罗马尼亚 (Romania)",areaCode:"40"},
					{name:"俄罗斯联邦 (Russian Federation)",areaCode:"7"},
					{name:"卢旺达 (Rwanda)",areaCode:"250"},
					{name:"美属萨摩亚 (Samoa)",areaCode:"685"},
					{name:"圣马力诺共和国 (San Marino)",areaCode:"378"},
					{name:"沙特阿拉伯 (Saudi Arabia)",areaCode:"966"},
					{name:"塞内加尔 (Senegal)",areaCode:"221"},
					{name:"塞尔维亚共和国 (Serbia)",areaCode:"381"},
					{name:"塞舌尔 (Seychelles)",areaCode:"248"},
					{name:"塞拉利昂 (Sierra Leone)",areaCode:"232"},
					{name:"新加坡 (Singapore)",areaCode:"65"},
					{name:"斯洛伐克 (Slovakia (Slovak Republic))",areaCode:"421"},
					{name:"斯洛文尼亚 (Slovenia)",areaCode:"386"},
					{name:"索罗门群岛 (Solomon Islands)",areaCode:"677"},
					{name:"索马里 (Somalia)",areaCode:"252"},
					{name:"南非 (South Africa)",areaCode:"27"},
					{name:"西班牙 (Spain)",areaCode:"34"},
					{name:"斯里兰卡 (Sri Lanka)",areaCode:"94"},
					{name:"苏丹 (Sudan)",areaCode:"249"},
					{name:"苏里南 (Suriname)",areaCode:"597"},
					{name:"斯威士兰 (Swaziland)",areaCode:"268"},
					{name:"瑞典 (Sweden)",areaCode:"46"},
					{name:"瑞士 (Switzerland)",areaCode:"41"},
					{name:"叙利亚 (Syrian Arab Republic)",areaCode:"963"},
					{name:"塔吉克 (Tajikistan)",areaCode:"992"},
					{name:"坦桑尼亚 (Tanzania)",areaCode:"255"},
					{name:"泰国 (Thailand)",areaCode:"66"},
					{name:"多哥 (Togo)",areaCode:"228"},
					{name:"汤加 (Tonga)",areaCode:"676"},
					{name:"特立尼达和多巴哥 (Trinidad and Tobago)",areaCode:"1868"},
					{name:"突尼斯 (Tunisia)",areaCode:"216"},
					{name:"土耳其 (Turkey)",areaCode:"90"},
					{name:"土库曼 (Turkmenistan)",areaCode:"993"},
					{name:"土克斯及开科斯群岛 (Turks and Caicos Islands)",areaCode:"1809"},
					{name:"乌干达 (Uganda)",areaCode:"256"},
					{name:"乌克兰 (Ukraine)",areaCode:"380"},
					{name:"阿拉伯联合酋长国 (United Arab Emirates)",areaCode:"971"},
					{name:"英国 (United Kingdom)",areaCode:"44"},
					{name:"乌拉圭 (Uruguay)",areaCode:"598"},
					{name:"乌兹别克斯坦 (Uzbekistan)",areaCode:"998"},
					{name:"瓦努阿图 (Vanuatu)",areaCode:"678"},
					{name:"梵蒂冈 (Vatican City State (Holy See))",areaCode:"39"},
					{name:"委内瑞拉 (Venezuela)",areaCode:"58"},
					{name:"越南 (Vietnam)",areaCode:"84"},
					{name:"维尔京群岛(英国) (Virgin Islands (British))",areaCode:"1284"},
					{name:"维尔京群岛(美国) (Virgin Islands (U.S.))",areaCode:"1340"},
					{name:"西撒哈拉 (Western Sahara)",areaCode:"685"},
					{name:"也门 (Yemen)",areaCode:"967"},
					{name:"南斯拉夫 (Yugoslavia)",areaCode:"381"},
					{name:"赞比亚 (Zambia)",areaCode:"260"},
					{name:"津巴布韦 (Zimbabwe)",areaCode:"263"},
					{name:"阿布哈兹 (the Republic of Abkhazia)",areaCode:"7"},
					{name:"南奥赛梯 (the Republic of South Ossetia)",areaCode:"7"},
					{name:"泽西岛 (Bailiwick of Jersey)",areaCode:"44"},
					{name:"老挝 (Lao People s Democratic Republic)",areaCode:"856"},
					{name:"马其顿 (The Republic of Macedonia)",areaCode:"389"},
					{name:"圣基茨和尼维斯(The Federation of Saint Kitts and Nevis)",areaCode:"1869"},
					{name:"圣卢西亚岛 (Santa Luzia Island)",areaCode:"1758"},
					{name:"圣文森特和格林纳丁斯(Saint Vincent and the Grenadines)",areaCode:"1784"},
					{name:"圣多美和普林西比 (Sao Tome and Principe)",areaCode:"239"},
					{name:"南苏丹共和国 (The Republic of South Sudan)",areaCode:"211"}

				],

			}
		},
		mounted() {
			this.initPayPal()
		},
		created() {
			this.payList = this.$t('pay');
			this.cardList = this.$t('paymentMethods.list');
			console.log(this.cardList)
			// this.index = this.$route.query.index || 0;
			// this.info = this.$t('index.indexSeven.list')[this.index];
			this.id = this.$route.query.id || 0;
			this.num = this.$route.query.number || 0;
			// this.current = 1;
			this.goodsDetails();
			// this.text()
			// console.log(this.markets[this.navIndex].six)
		},
		methods: {
			currentFun(index){
				var that = this;
				that.current = index;
				if(index == 1){
					setTimeout(function(){
						that.initPayPal();
					},500)
				}

			},
		  allFieldsHaveValue(obj) {
			for (let key in obj) {
			  if (obj[key]==='') {
				return false;
			  }
			}
			return true;
		  },
		  initPayPal(){
			var that = this;
			window.paypal.Buttons({
				  async createOrder() {
					for(var i = 0;i<that.payList.length;i++){
					  var item = that.payList[i];
					  // console.log(item)
					  if(!item.value && item.required){
						that.$message({
						  message: item.type == 'select'?(that.$t('buttom.tipss')+ item.title):(that.$t('buttom.tips')+item.title),
						  type: 'error',
						  duration:3000
						});
						throw new Error("create order error");
					  }
					}
					if(that.current == 0){
					  var cardList = that.cardList[0].list;
					  for(var a = 0;i<cardList.length;a++){
						var sitem = cardList[a];
						console.log(sitem)
						if(!sitem.value){
						  that.$message({
							message: that.$t('buttom.tips')+sitem.title,
							type: 'error',
							duration:3000
						  });
						  throw new Error("create order error");
						}
					  }
					}
					if(!that.agree){
					  that.$message({
						message: localStorage.getItem('lang') == 'zh'?'请先阅读并同意网站条款和条件':'Please read and agree to the website terms and conditions first',
						type: 'error',
						duration:3000
					  });
					  throw new Error("create order error");
					}
					that.payInfo.goodsId = that.id;
					that.payInfo.payWay = that.current == 2?'airwallex':'payPal';
					that.payInfo.userName = that.payList[0].value+'·'+that.payList[1].value;
					that.payInfo.country = that.payList[2].value;
					that.payInfo.phone = that.payList[6].value;
					that.payInfo.email = that.payList[7].value;
					that.cardInfo.name = that.cardList[0].list[0].value;
					that.cardInfo.number = that.cardList[0].list[1].value;
					that.cardInfo.expiry = that.cardList[0].list[2].value;
					that.cardInfo.securityCode = that.cardList[0].list[3].value;

					try {
					  const response = await that.$http.post("/xcmarket/createOrder", that.payInfo);

					  console.log('createOrder response===>',response)
					  const orderData = await response.data;
					  if (orderData.orderNo) {
						return orderData.orderNo;
					  } else {
						throw new Error("create order error");
					  }
					} catch (error) {
					  console.error(error);
					  this.resultMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
					}
				  },
				  async onApprove(data, actions) {
					console.log('data==>',data)
					console.log('actions==>',actions)
					try {
					  const response = await that.$http.post(`/xcmarket/confirmPay?orderNo=`+data.orderID);
					  console.log('onApprove response===>',response)
					  if (response.code === 200) {
						window.location.href = '/paymentSuccessful?token='+data.orderID;
						return actions.restart();
					  } else {
						this.resultMessage(response.msg);
					  }
					} catch (error) {
					  console.error(error);
					  this.resultMessage(
						  `Sorry, your transaction could not be processed...<br><br>${error}`,
					  );
					}
				  },
				}).render("#paypal-button-container");
		  },
		  resultMessage(message){
			const container = document.querySelector("#result-message");
			container.innerHTML = message;
		  },
			text(){
				var that = this;
				that.$http.post('xcmarket/confirmPay?'+that.objectToQueryString(that.cardInfo)).then((res)=>{
					console.log(res)

				}).catch((err)=>{
				   console.log(err)
				})
			},
			objectToQueryString(obj){
				return Object.keys(obj)
				  .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
				  .join('&');
			},
			selectFun(){
				this.isSelect = !this.isSelect;
			},
			chooseFun(index){
				this.payList[2].value = this.countryList[index].name;
				this.isSelect = !this.isSelect;
				// this.$set(this.isSelect,'',!this.isSelect)
				// this.$forceUpdate();
				// this.selectFun();
				// this.$set(this.isSelect,!this.isSelect)
				console.log(this.isSelect)

			},
			goodsDetails(){
				const loading = this.$loading({
				  lock: true,
				  text: this.$t('eMsg.loading'),
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.3)'
				});
				this.$http.get('xcmarket/goodsDetails?goodsId='+this.$route.query.id).then((res)=>{
					// res.data.categoryName = this.removeChar(res.data.categoryName,'<br/>');
					res.data.categoryName = (res.data.categoryName).replace(/<b>|<\/b>|<br\/>/g, "");
					this.goodsInfo = res.data;
					loading.close();
				}).catch((err)=>{
				   console.log(err)
				   loading.close();
				})
			},
			removeChar(str,char){
				var regex = new RegExp(char, 'g');
				var result = str.replace(regex, '');
				return result;
			},

			submitFun(){
				var that = this;
				for(var i = 0;i<that.payList.length;i++){
					var item = that.payList[i];
					// console.log(item)
					if(!item.value && item.required){
						that.$message({
						  message: item.type == 'select'?(that.$t('buttom.tipss')+ item.title):(that.$t('buttom.tips')+item.title),
						  type: 'error',
						  duration:3000
						});
						return;
					}
				}
				if(that.current == 0){
					var cardList = that.cardList[0].list;
					for(var i = 0;i<cardList.length;i++){
						var sitem = cardList[i];
						console.log(sitem)
						if(!sitem.value){
							that.$message({
							  message: that.$t('buttom.tips')+sitem.title,
							  type: 'error',
							  duration:3000
							});
							return;
						}
					}
				}
				if(!that.agree){
					that.$message({
					  message: localStorage.getItem('lang') == 'zh'?'请先阅读并同意网站条款和条件':'Please read and agree to the website terms and conditions first',
					  type: 'error',
					  duration:3000
					});
					return;
				}
				const loading = this.$loading({
				  lock: true,
				  text: 'loading',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.3)'
				});
				that.payInfo.goodsId = that.id;
				that.payInfo.payWay = that.current == 2?'airwallex':'payPal';
				that.payInfo.userName = that.payList[0].value+'·'+that.payList[1].value;
				that.payInfo.country = that.payList[2].value;
				that.payInfo.phone = that.payList[6].value;
				that.payInfo.email = that.payList[7].value;
				that.cardInfo.name = that.cardList[0].list[0].value;
				that.cardInfo.number = that.cardList[0].list[1].value;
				that.cardInfo.expiry = that.cardList[0].list[2].value;
				that.cardInfo.securityCode = that.cardList[0].list[3].value;
				that.$http.post('xcmarket/createOrder',that.payInfo).then((res)=>{
					console.log(res)
					that.cardInfo.orderNo = res.data.orderNo;
					that.paypalUrl = res.data.payUrl;
					if(that.current == 2){
						window.open(that.paypalUrl);
						loading.close();
						that.airwallexFun(res.data.orderNo);
					}else{
						that.$http.post('xcmarket/confirmPay?'+that.objectToQueryString(that.cardInfo)).then((res)=>{
							console.log(res)
							loading.close();
						}).catch((err)=>{
						   console.log(err)
						   loading.close();
						})
					}


				}).catch((err)=>{
				   console.log(err)
				   loading.close();
				})
				// window.open('https://www.paypal.com/paypalme/ychpro168')
			},
			airwallexFun(orderNo){
				var that = this;
				// 如果你改你就换一下接口地址
				const payLoading = this.$loading({
				  lock: true,
				  text: 'Waiting......',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.3)'
				});
        console.log("payLoading:",payLoading)
				var setVal = setInterval(function(){
					that.$http.get('xcmarket/queryOrderInfo?orderNo='+orderNo).then((res)=>{
						// 要带参  成功的参数
						if(res.data.result == 'success'){
							that.$router.push({path:'/paymentSuccessful?token='+orderNo})
							clearInterval(setVal)
              payLoading.close();
						}
            // else{
						// 	that.$router.push({path:'/cancelPayment'})
						// 	clearInterval(setVal)
						// }
					}).catch((err)=>{
					  // payLoading.close();
					  clearInterval(setVal)
					})
				},3000)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.paymentBox{
		width: 100%;
		height: auto;
		padding: 0.5rem 18.75% 2rem;
		display: flex;
		justify-content: space-between;
		.paymenttitle{
			width: 100%;
			height: 0.7rem;
			display: flex;
			align-items: center;
			font-size: 0.24rem;
			color: #282938;
			font-family: 'Poppins-Medium';
		}
		.paymentLeft{
			width: 7rem;
			height: auto;
		}
		.formBox{
			width: 100%;
			height: auto;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.sameInput{
				width: 100%;
				height: auto;
				padding-bottom: 0.2rem;
				position: relative;
				.sameFormInputSelect{
					width: 100%;
					height: 6.4rem;
					background-color: #ffffff;
					border: 1px solid #f3f3f3;
					border-radius: 0.2rem;
					position: absolute;
					left:0;
					top: 1.2rem;
					z-index: 11;
					overflow: hidden;
					overflow-y: scroll;
					transition: all 0.3s;
					display: none;
					.sameCountry{
						width: 100%;
						height: 0.8rem;
						padding: 0 0.2rem;
						border-bottom: 1px solid #f3f3f3;
						font-size: 0.16rem;
						color: #202020;
						display: flex;
						align-items: center;
					}
				}
				.sameFormInputSelectMore{
					display: block;
					transition: all 0.3s;
				}
				.title{
					width: 100%;
					height: 0.5rem;
					display: flex;
					align-items: center;
					font-size: 0.16rem;
					color: #282938;
					font-family: 'Poppins-Regular';
					>img{
						width: 0.1rem;
						height: 0.1rem;
						margin-left: 0.1rem;
					}
				}
				.sameSelect{
					width: 100%;
					height: 0.64rem;
					padding: 0 0.28rem;
					border: 1px solid rgba(0,0,0,0.12);
					border-radius: 0.08rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 0.16rem;
					color: #282938;
					font-family: 'Poppins-Regular';
					>img{
						width: 0.2rem;
						height: 0.2rem;
					}
				}
				.sameCardInput{
					width: 100%;
					height: 0.5rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border: 1px solid rgba(0,0,0,0.12);
					border-radius: 0.08rem;
					padding: 0 0.1rem;
					>input{
						flex: 1;
						height: 0.5rem;
						line-height: normal;
						font-size: 0.16rem;
						color: #282938;
						font-family: 'Poppins-Regular';
					}
					>img{
						width: 0.3rem;
						height: 0.3rem;
						margin-left: 0.1rem;
					}
				}
				.sameinput{
					width: 100%;
					height: 0.64rem;
					line-height: normal;
					padding: 0 0.28rem;
					border: 1px solid rgba(0,0,0,0.12);
					border-radius: 0.08rem;
					font-size: 0.16rem;
					color: #282938;
					font-family: 'Poppins-Regular';
				}
			}
			.sameInputHalf{
				width: 48%;
			}
		}
		.paymentRight{
			width: 4.5rem;
			height: auto;
			.paymentRightBox{
				width: 100%;
				height: auto;
				border: 1px solid #D8D8D8;
				border-radius: 0.08rem;
				padding: 0.1rem 0.25rem 0.6rem;
				.paymentRightTitle{
					width: 100%;
					height: 0.6rem;
					display: flex;
					align-items: center;
					font-size: 0.16rem;
					color: #282938;
					font-family: 'Poppins-Medium';
				}
				.option{
					width: 100%;
					height: 0.5rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 0.16rem;
					color: #282938;
					font-family: 'Poppins-Regular';
					>span{
						color: #666772;
					}
				}
				.subtotal{
					height: 0.7rem;
					font-family: 'Poppins-Medium';
					border-bottom: 1px solid #D8D8D8;
					>span{
						font-family: 'Poppins-Regular';
					}
				}
				.total{
					font-family: 'Poppins-SemiBold';
					font-size: 0.18rem;
					>span{
						font-family: 'Poppins-Medium';
						font-size: 0.24rem;
						color: #282938;
					}
				}
				.paymentMethods{
					width: 100%;
					height: auto;
					border-bottom: 1px solid #D8D8D8;
					.paymentMethodsTitle{
						width: 100%;
						height: 0.7rem;
						display: flex;
						align-items: center;
						font-size: 0.16rem;
						color: #282938;
						font-family: 'Poppins-Medium';
						margin-top: 0.2rem;
					}
					.payList{
						width: 100%;
						height: auto;
						.payListTitle{
							width: 100%;
							height: auto;
							display: flex;
							align-items: center;
							padding: 0.1rem 0;
							cursor: pointer;
							.payListTitleChoose{
								width: 0.14rem;
								height: 0.14rem;
								margin-right: 0.1rem;
							}
							.payListTitleRight{
								width: auto;
								height: auto;
								display: flex;
								flex-direction: column;
								font-size: 0.14rem;
								color: #666772;
								>span{
									color: #A0A1A7;
								}
							}
							.payListTitlePay{
								width: 0.4rem;
								height: 0.4rem;
								margin-left: 0.1rem;
							}
						}
					}
				}
				.payDesc{
					width: 100%;
					height: auto;
					font-size: 0.14rem;
					color: #A0A1A7;
					line-height: 0.25rem;
					margin: 0.1rem auto 0;
				}
				.payAgree{
					width: 100%;
					height: auto;
					font-size: 0.14rem;
					color: #666772;
					padding: 0.2rem 0;
					display: flex;
					align-items: center;
					cursor: pointer;
					>img{
						width: 0.14rem;
						height: 0.14rem;
						margin-right: 0.05rem;
					}
				}
				.placeOrderone{
					width: 100%;
					height: 0.64rem;
					background-color: #FCD980;
					border-radius: 0.4rem;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					margin-bottom: 0.2rem;
					>img{
						width: 0.92rem;
						height: 0.46rem;
					}
				}
				.placeOrder{
					width: 100%;
					height: 0.64rem;
					background-color: #282938;
					border-radius: 0.4rem;
					font-size: 0.2rem;
					color: #F4F6FC;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					font-family: 'Manrope-SemiBold';
				}
				.airwallex{
					background-color: #e6e6e6;
					>img{
						width: 2rem;
						height: auto;
					}
				}
			}
		}
	}
	@media only screen and (max-width:1080px){
		.paymentBox{
			padding: 0.5rem 0.5rem 1rem;
			flex-wrap: wrap;
			.paymenttitle{
				height: 0.9rem;
				font-size: 0.36rem;
			}
			.paymentLeft{
				width: 100%;
				height: auto;
			}
			.formBox{
				.sameInput{
					padding-bottom: 0.3rem;
					.sameFormInputSelect{
						.sameCountry{
							font-size: 0.26rem;
						}
					}
					.title{
						height: 0.6rem;
						font-size: 0.3rem;
						>img{
							width: 0.2rem;
							height: 0.2rem;
							margin-left: 0.1rem;
						}
					}
					.sameSelect{
						height: 0.8rem;
						font-size: 0.26rem;
						>img{
							width: 0.3rem;
							height: 0.3rem;
						}
					}
					.sameCardInput{
						height: 0.8rem;
						>input{
							height: 0.8rem;
							font-size: 0.26rem;
						}
						>img{
							width: 0.3rem;
							height: 0.3rem;
							margin-left: 0.1rem;
						}
					}
					.sameinput{
						height: 0.8rem;
						font-size: 0.26rem;
					}
				}
				.sameInputHalf{
					width: 48%;
				}
			}
			.paymentRight{
				width: 100%;
				height: auto;
				margin-top: 0.5rem;
				.paymentRightBox{
					.paymentRightTitle{
						height: 0.7rem;
						font-size: 0.3rem;
					}
					.option{
						height: 0.6rem;
						font-size: 0.24rem;
					}
					.total{
						font-size: 0.28rem;
					}
					.paymentMethods{
						padding: 0.3rem 0;
						.paymentMethodsTitle{
							font-size: 0.26rem;
						}
						.title{
							font-size: 0.24rem;
						}
						.sameCardInput{
							padding: 0 0.2rem;
							>img{
								width: 0.4rem;
								height: 0.4rem;
							}
						}
						.payList{
							width: 100%;
							height: auto;
							.payListTitle{
								.payListTitleChoose{
									width: 0.3rem;
									height: 0.3rem;
									margin-right: 0.1rem;
								}
								.payListTitleRight{
									font-size: 0.26rem;
								}
							}
						}
					}
					.payDesc{
						line-height: 0.3rem;
						font-size: 0.24rem;
						margin: 0.3rem auto;
					}
					.payAgree{
						font-size: 0.24rem;
						margin: 0.2rem auto;
						>img{
							width: 0.3rem;
							height: 0.3rem;
							margin-right: 0.1rem;
						}
					}
					.placeOrderone{
						width: 100%;
						height: 0.8rem;
					}
					.placeOrder{
						width: 100%;
						height: 0.8rem;
						font-size: 0.3rem;
					}
				}
			}
		}

	}
</style>
