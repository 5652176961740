<template>
	<div id="indicatorAnalyst">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- 内容 -->
		<div class="chiefAnalystBox">
			<div  @click="jumpLink()"> <span class="bannerTitle">{{ $t('indicatorAnalyst.banner.title') }}</span> <span  class="bannerDesc">(Form 8:00AM -- 4:00PM UK time)</span></div>
			<div class="bannerDesc">{{ $t('indicatorAnalyst.banner.desc') }}</div>
			<div class="bannerVideo" v-if="videoInfo.playUrl">
			 <VueAliplayer :vid="vid" @clickScreen="jumpLink" :source="videoInfo.playUrl" :cover="videoInfo.cover" :options="options"></VueAliplayer>
				<!-- <img src="@/assets/images/banner/chiefBanner.png"/> -->
				<!-- <video controls muted  loop id="video" :src="videoInfo.playUrl" :poster="videoInfo.cover">
				</video> -->
				<!-- <video class="video" id="player-indicatorAnalyst" preload="auto" playsinline webkit-playsinline>
				</video> -->
			</div>
			<div class="bannerVideo" v-else>
				<img :src="videoInfo.cover"/>
			</div>
			<div class="indicatorTwo">
				<div class="left">
					<div class="title">{{ $t('indicatorAnalyst.title') }}</div>
					<div class="sameBox">
						<div class="list" v-for="(item,index) in $t('indicatorAnalyst.list')" :key="index">
							<span>0{{item.id}}</span>
							<div>{{item.title}}</div>
						</div>
					</div>
				</div>
				<div class="right">
					<img :src="$t('indicatorAnalyst.img')"/>
				</div>
			</div>
		</div>
		<!--  -->
	</div>
</template>

<script>
	import VueAliplayer from "vue-aliplayer-v2";

	// import TCPlayer from 'tcplayer.js';
	// import 'tcplayer.js/dist/tcplayer.min.css';
	export default {
		name: 'indicatorAnalyst',
		components:{
			 VueAliplayer,
		},
		data() {
			return {
				 vid:'pc_jPrismPlayer',
				videoInfo:{},
				options:{
					width: '100%',                //播放器宽度
					height: '7.1rem',              //播放器高度
					isLive: false,                 //播放内容是否为直播，直播时会禁止用户拖动进度条。
					playsinline:true,             //H5 是否内置播放
					useH5Prism:true,              //指定使用 H5 播放器。
					rePlay:false,                 //播放器自动循环播放.
					preload:true,                 //播放器自动加载，目前仅 h5 可用。
					controlBarVisibility:'click', //控制面板的实现，默认为‘hover’。可选的值为：‘click’、‘hover’、‘always’。
					autoplay:false,               //播放器是否自动播放，在移动端 autoplay 属性会失效。Safari11 不会自动开启自动播放如何开启。
					enableSystemMenu:true,        //是否允许系统右键菜单显示，默认为 false。
					loadDataTimeout:5,            //缓冲多长时间后，提示用户切换低清晰度，默认：20 秒。
					showBarTime:'10000',          //控制栏自动隐藏时间（ms）。
					disableSeek:true,            //禁用进度条的 Seek，默认为 false，仅 Flash 支持。
				}
			}
		},
		mounted() {

		},
		created() {
			this.roomInfo();

			// console.log(this.markets[this.navIndex].six)
		},
		methods: {
			jumpLink(){
				window.open('https://live.xc600.com?roomId=1668486336190148609&source=XC')
			},
			roomInfo(){
				var that = this;
				const loading = this.$loading({
				  lock: true,
				  text: this.$t('eMsg.loading'),
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.3)'
				});
				that.$http.get('xcmarket/roomInfo?roomId=1668486336190148609').then((res)=>{
					that.videoInfo = res.data;
					console.log(that.videoInfo)
					// var player = TCPlayer('player-indicatorAnalyst', {
					//     sources: [{
					//       src: that.videoInfo.playUrl,
					// 	  poster:that.videoInfo.qrCode,
					//     }],
					//     licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1308111394_1/v_cube.license',
					// });
					// player-container-id 为播放器容器 ID，必须与 html 中一致
					 // player.src(that.videoInfo.playUrl);
					 // url 播放地址
					// player.src(url); // url 播放地址
					loading.close();
				}).catch((err)=>{
				   console.log(err)
				   loading.close();
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.chiefAnalystBox{
		width: 100%;
		height: auto;
		padding: 0.4rem 18.75% 2rem;
		.bannerTitle{
			width: 100%;
			height: 0.9rem;
			line-height: 0.45rem;
			//display: flex;
			align-items: center;
			font-size: 0.32rem;
			color: #282938;
			font-family: 'Poppins-Medium';
			cursor: pointer;
		}
		.bannerDesc{
			width: 100%;
			height:auto;
      margin-top: 12px;
			line-height: 0.35rem;
			font-size: 0.26rem;
			color: #282938;
			font-family: 'Poppins-Regular';
			margin-bottom: 0.5rem;
		}
		.bannerVideo{
			width: 100%;
			height: auto;
			display: flex;
			justify-content: center;
			align-items: center;
			>img{
				width: 100%;
				height: 7.1rem;
				display: block;
			}
			.video{
				width: 100%;
				height: 7.1rem;
			}
		}
		.indicatorTwo{
			width: 100%;
			height: auto;
			display: flex;
			padding: 1.2rem 0 2rem;
			justify-content: space-between;
			.left{
				flex: 1;
				height: auto;
				.title{
					width: 100%;
					height: 0.7rem;
					display: flex;
					font-size: 0.38rem;
					color: #282938;
					font-family: 'Poppins-SemiBold';
				}
				.sameBox{
					width: 100%;
					height: auto;
					.list{
						width: 100%;
						height: auto;
						display: flex;
						align-items: center;
						padding: 0.18rem 0;
						>span{
							width: 0.5rem;
							font-size: 0.24rem;
							color: #2405F2;
							font-family: 'Poppins-Medium';
						}
						>div{
							flex: 1;
							line-height: 0.35rem;
							font-size: 0.2rem;
							color: #282938;
							font-family: 'Poppins-Medium';
						}
					}
				}
			}
			.right{
				width: 5.82rem;
				height: 3.8rem;
				margin-left: 0.6rem;
				>img{
					display: block;
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	@media only screen and (max-width:1080px){
		.chiefAnalystBox{
			padding: 0.5rem 0.5rem 1rem;
			.bannerVideo{
				height: auto;
				.video{
					width: 100%;
					height: 5.5rem;
				}
				>img{
					width: 100%;
					height: 5.5rem;
					display: block;
				}
			}
			.indicatorTwo{
				width: 100%;
				flex-wrap: wrap;
				.left{
					flex: 1;
					height: auto;
					.title{
						justify-content: center;
					}
					.sameBox{
						.list{
							>span{
								width: 0.6rem;
								font-size: 0.28rem;
							}
							>div{
								line-height: 0.4rem;
							}
						}
					}
				}
				.right{
					width: 100%;
					height: 3.8rem;
					margin-left: 0;
					>img{
						display: block;
						width: 100%;
						height: 100%;
					}
				}
			}
		}

	}


</style>
