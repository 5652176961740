<template>
  <div id="App" :class="headerShow?'AppClassNo':'AppClass'">
<!--    <nav>-->
<!--      <router-link to="/">Home</router-link> |-->
<!--      <router-link to="/about">About</router-link>-->
<!--    </nav>-->
	<div v-if="headerShow">
		<Header :navList="navList"></Header>
	</div> 
	<div v-if="!headerShow">
		<HeaderIndex :navList="navList"></HeaderIndex>
	</div>
    <router-view></router-view>
	<div>
		<Footer></Footer>
	</div>
  </div>
</template>
<script>
	import Header  from './components/layout/header';
	import HeaderIndex  from './components/layout/headerIndex';
	import Footer  from './components/layout/footer';
	export default {
		  name: 'App',
			data() {
			return {
			  headerShow:false,
			  navList:[]
			};
		  },
	    components:{
	        Header,
			HeaderIndex,
			Footer
	    },
		created() {
			this.$i18n.locale = "en";
			localStorage.setItem('lang',"en");
			this.navList = this.$t('nav');
			this.goodsCategoryList();
			this.routeFun()
		},
		watch:{
			$route(to,from) {
			 if (this.$route.path === "/" || this.$route.path === "/index") {
			 	this.headerShow = false;
			 } else {
			 	this.headerShow = true;
			 }
			},
		},
		methods: {
			goodsCategoryList(){
				this.$http.get('xcmarket/goodsCategoryList').then((res)=>{
					var product = [];
					res.data.forEach((item,index)=>{
						if(item.goodsNum>0){
							var str = item.title.split('<br/>')[0];
							var result = str.replace(/<b>|<\/b>|<br\/>/g, "");
							product.push({show:true,id:item.id,title:result,src:'/activateMembership?id='+item.id+'&index=3'})
						}
					})
					this.navList[3].list = product;
					// this.$set(this.navList[3],'list',product);
					// this.$forceUpdate();
					console.log(this.navList)
					
				}).catch((err)=>{
				   console.log(err) 
				   // loading.close();
				})
			},
			routeFun(){
				if (this.$route.path === "/" || this.$route.path === "/index") {
					this.headerShow = false;
				} else {
					this.headerShow = true;
				}
			},
			request(){
				this.$http.post('/itemProps/addItemProps',{
				　　name: this.name,
				　　parentId:this.parentId,
				　　sortOrder:0
				}).then((res)=>{
				　　console.log(res)
				}).catch((err)=>{
				   alert("请求失败") 
				})
				//get
				this.$http.get("/roles/getPersonnelInfoRoles",{
				　　params:{
				　　　　key:"value"
				　　}
				}).then((res) => {
				　　console.log(res)
				}).catch((err)=>{
				　　alert("请求失败")
				})
			}
			
		}
	}
</script>
<style>
.el-icon-loading{
	font-size: 1rem;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.AppClass{
	width: 100%;
	height: 100%;
}
.AppClassNo{
	width: 100%;
	height: auto;
}
.seizeSeat{
	width: 100%;
	height: 0.9rem;
}
.display{
	display: flex;
	align-items: center;
}
.discen{
	display: flex;
	justify-content: center;
	align-items: center;
}
.disbet{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.discolcen{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.ellipsisNew{
	white-space: normal;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}
.indexTitle{
		width: 100%;
		height: auto;
		font-size: 0.38rem;
		color: #282938;
		font-family: 'Poppins-SemiBold';
		margin: 0.16rem auto;
		text-align: center;
	}
	/* .indexSubTitle{
		width: 100%;
		height: auto;
		padding: 0 1rem;
		font-size: 0.16rem;
		color: #7F8284;
		margin: 0.1rem auto 0;
		text-align: center;
	} */
@media only screen and (max-width:1080px){
	.seizeSeat{
		width: 100%;
		height: 0.9rem;
	}
}
</style>
